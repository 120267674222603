import { Component } from '@angular/core';
import { Router, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { AuthService } from './auth.service';
import { DataService } from './firebase.service';
import { FirestoreService } from './firestore.service';
import { MessageService } from './message.service';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    standalone: true,
    imports: [RouterLink, RouterLinkActive, MatIconButton, MatIcon, RouterOutlet]
})
export class AppComponent {
  title = 'app';

  constructor(
    public ds: DataService,
    private ms: MessageService,
    public as: AuthService,
    private router: Router,
    private auth: AuthService,
    private afs: FirestoreService
  ) {

    // this.afs.periodForGettingRBSOrders();
  }

  isLoggedIn() {
    if (!this.auth) {
      return false;
    }
    return this.auth.isLoggedIn();
  }
  isSAH() {
    if (this.auth.getCurrentUser() && (
      this.auth.getCurrentUser().email.includes('sah-sh.ch') === true ||
      // this.auth.getCurrentUser().email.includes('rhyno.ch') === true ||
      this.auth.getCurrentUser().email.includes('bircher.eu') === true)
    ) {
      return true;
    }

    return false;
  }

  logout() {
    const that = this;
    this.as
      .logout()
      .then((value) => {
        that.ms.alertSuccess('Ciao', 'Bis zum nächsten Mal!');
      })
      .catch((err) => {
        that.ms.alertError('Fehler: ' + (err as any).code, err.message);
      });
  }

  navigate(url) {
    this.router.navigateByUrl(url);
  }
}
