import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'object',
    standalone: true
})
export class ObjectPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    switch (value) {
      case 'competenceprofile':
        return 'Kompetenzprofil';
      case 'person':
        return 'Person';
      case 'child':
        return 'Kind';
      case 'class':
        return 'Klasse';
      case 'waitinglist':
        return 'Warteliste';
      case 'classperson':
        return 'Teilnehmer in Klasse';
      case 'personcompetence':
        return 'Kompetenzen einer Person';
      case 'personpresence':
        return 'Präsenz einer Person';
      case 'classsection':
        return 'Unterrichtseinheit';
      case 'useraccount':
        return 'Benutzer';
      case 'userfunction':
        return 'Funktion eines Benutzers';
      case 'authrolemember':
        return 'Berechtungsrollen Zuordnung';
      case 'module':
        return 'Lernangebot';
      case 'course':
        return 'Fachbereich';

      default:
        return value;
    }
  }
}
