import { Location, AsyncPipe, NgClass, DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { Database } from '@angular/fire/database';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import { MatTableDataSource, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { Observable } from 'rxjs';
import { AuthService } from '../auth.service';
import { ExcelService } from '../excel.service';
import { DataService } from '../firebase.service';
import { FirestoreService } from '../firestore.service';
import { MessageService } from '../message.service';
import firebase from 'firebase/compat/app';
import { MatDatepickerInput, MatDatepickerToggle, MatDatepicker } from '@angular/material/datepicker';
import { MatInput } from '@angular/material/input';
import { MatOption } from '@angular/material/core';
import { FormsModule } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { MatFormField, MatSuffix } from '@angular/material/form-field';
import { PresencePartPipe } from '../mpipes/presence-part.pipe';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { AnalyticsPresenceComponent } from './supervisor-presence/analytics-presence.component';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

@Component({
    selector: 'interpret-orders',
    templateUrl: './orders.component.html',
    styleUrls: ['./home.component.css'],
    standalone: true,
    imports: [MatFormField, MatSelect, FormsModule, MatOption, MatInput, MatDatepickerInput, MatDatepickerToggle, MatSuffix, MatDatepicker, MatTable, MatSort, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatSortHeader, MatCellDef, MatCell, NgClass, MatIcon, MatIconButton, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatPaginator, AsyncPipe, DatePipe]
})
export class subComponentOrders {
  @Input() customer: any;
  @ViewChild(MatSort, { static: false }) materialSort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  displayedColumns = [
    //   'customer',
    'date',
    'durationDolmetsch',
    'durationTravel',
    'durationWait',
    'durationCall',
    //   'dolmetschCategory',
    //   'dolmetschType',
    'city',
    'language',
    'dolmetscher',
    // 'rbsbernpk',
    'status',
    'gotoOrderDetail'
    //   'change',
    //   'delete',
  ];

  newItem: any;
  itemCreateMode: any;
  filter: any;
  sort: any;

  dataList: any = [];
  dataListFiltered: any = [];
  dataSource = new MatTableDataSource(this.dataListFiltered);
  dataSubsubscription: any;

  bool: any = [];

  customers: any = [];
  dolmetschCategories: any = [];
  dolmetschType: any = [];
  languages: any = [];
  dolmetscher: any = [];
  today: any = moment();

  // MatPaginator Output
  pageEvent: PageEvent;

  // MatPaginator Inputs
  length: any;
  pageSize: any = 10;
  pageSizeOptions = [5, 10, 25, 100];

  constructor(public fs: FirestoreService, private router: Router, public dialog: MatDialog) {
    this.filter = { active: true, date: null };
    this.sort = { value: '', direction: '', sorter: [] };
    this.customers = this.fs.getCustomerList();
    this.dolmetschCategories = this.fs.getDolmetschCategoryList();
    this.languages = this.fs.getLanguageList();
    this.dolmetschType = this.fs.getDolmetschTypeList();
  }

  ngOnInit() {
    this.doSearch();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.materialSort;
    this.dataSource.paginator = this.paginator;
  }
  doSearch() {
    console.log('DoSearch');
    console.log(this.customer.id);
    this.dataSubsubscription = this.fs.getOrderListOfSupervisor(this.customer.id).subscribe((data) => {
      console.log(data);

      data.map((c) => {
        this.fs.getOpenRequestsForOrder(c).then((av) => {
          c.openRequests = av;
        });
      });
      for (const i of data) {
        let index;
        switch (i.change) {
          case 'added':
            index = this.dataList.map((e) => e.id).indexOf(i.id);
            if (index >= 0) {
              if (i.active === false) {
                this.dataList.splice(index, 1);
              } else {
                this.dataList[index] = i;
              }
            } else {
              this.dataList.push(i);
            }
            break;

          case 'modified':
            index = this.dataList.map((e) => e.id).indexOf(i.id);
            if (i.active === false) {
              this.dataList.splice(index, 1);
            } else {
              this.dataList[index] = i;
            }
            break;

          case 'removed':
            index = this.dataList.map((e) => e.id).indexOf(i.id);
            this.dataList.splice(index, 1);
            break;

          default:
            // code...
            break;
        }
      }

      this.changeFilter();
    });
  }

  ngOnDestroy() {
    console.log('Destroy');
    this.dataSubsubscription.unsubscribe();
  }

  change() {}
  changeFilter() {
    this.dataListFiltered = this.dataList.filter((i) => {
      // console.log(i)
      if (this.fs.filter.customer === '') {
        return true;
      }
      if (i.customerId === this.fs.filter.customer) {
        return true;
      } else {
        return false;
      }
    });
    this.dataListFiltered = this.dataListFiltered.filter((i) => {
      // console.log(i)
      if (this.fs.filter.dolmetschCategory === '') {
        return true;
      }
      if (i.dolmetschCategoryId === this.fs.filter.dolmetschCategory) {
        return true;
      } else {
        return false;
      }
    });
    this.dataListFiltered = this.dataListFiltered.filter((i) => {
      // console.log(i)
      if (this.fs.filter.language === '') {
        return true;
      }
      if (i.languageId === this.fs.filter.language) {
        return true;
      } else {
        return false;
      }
    });
    this.dataListFiltered = this.dataListFiltered.filter((i) => {
      // console.log(i)
      if (this.fs.filter.dolmetschType === '') {
        return true;
      }
      if (i.dolmetschTypeId === this.fs.filter.dolmetschType) {
        return true;
      } else {
        return false;
      }
    });
    this.dataListFiltered = this.dataListFiltered.filter((i) => {
      // console.log(i)
      if (!this.fs.filter.date) {
        return true;
      }
      if (moment(i.dateFrom.seconds * 1000).isSame(moment(this.fs.filter.date), 'day')) {
        return true;
      } else {
        return false;
      }
    });
    this.dataListFiltered = this.dataListFiltered.filter((i) => {
      if (this.fs.filter.dolmetscher === '') {
        return true;
      } else {
        // console.log(i.id)
        if (i._dolmetscher) {
          const filterValue = this.fs.filter.dolmetscher.toLowerCase();
          const res = filterValue.split(' ');
          // console.log(res)
          const res2 = res.map((d) => {
            return {
              text: d,
              found: false
            };
          });
          // console.log(res2)

          const res3 = res2.map((d) => {
            if (i._dolmetscher && (i._dolmetscher.firstname || i._dolmetscher.lastname)) {
              if (
                i._dolmetscher.firstname.toLowerCase().indexOf(d.text) !== -1 ||
                i._dolmetscher.lastname.toLowerCase().indexOf(d.text) !== -1
              ) {
                d.found = true;
              } else {
                d.found = false;
              }
              return d;
            } else {
              return false;
            }
          });
          // console.log(res3)
          const notfound = res3.findIndex((x) => x.found === false);

          // console.log(notfound)
          if (notfound >= 0) {
            return false;
          } else {
            return true;
          }
        } else {
          return false;
        }
      }
    });

    this.dataSource.data = this.dataListFiltered;
  }
  applyFilter() {
    let filterValue = this.fs.filter.orders.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }
  applyFilterDolmetscher() {
    let filterValue = this.fs.filter.dolmetscher.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
    // console.log(this.dataSource)
  }

  toMoment(date): any {
    return moment(date);
  }
  gotoOrderDetail(item) {
    this.router.navigate(['/order', item.id]);
  }
  isWrittenTranslation(item) {
    if (item.dolmetschTypeId == '9Q88e2frktuGSXxuPbi9') {
      return true;
    }
    return false;
  }
}

@Component({
    selector: 'classpersons',
    templateUrl: './classperson.component.html',
    styleUrls: ['./home.component.css'],
    standalone: true,
    imports: [MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, NgClass, MatIconButton, MatIcon, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, DatePipe, PresencePartPipe]
})
export class subComponentClassPersons implements OnInit {
  @Input() classId: string;
  @ViewChild(MatSort, { static: false }) materialSort: MatSort;

  displayedColumns = [
    'firstname',
    'lastname',
    'name',
    'unexcused',
    'attendance',
    'section1',
    'section7',
    'section2',
    'section9',
    'section3',
    'section4',
    'section8',
    'section5',
    'section10',
    'section6',
    'gotoperson'
  ];

  public classPersonList: any = [];
  dataSource = new MatTableDataSource(this.classPersonList);

  public presence1: any;
  public presence2: any;
  public presence3: any;
  public presence4: any;
  public presence5: any;
  public presence6: any;
  public presence7: any;
  public presence8: any;
  public presence9: any;
  public presence10: any;

  public section1: any;
  public section2: any;
  public section3: any;
  public section4: any;
  public section5: any;
  public section6: any;
  public section7: any;
  public section8: any;
  public section9: any;
  public section10: any;

  constructor(
    public ds: DataService,
    private db: Database,
    private ms: MessageService,
    private route: ActivatedRoute,
    private router: Router,
    private _location: Location
  ) {
    this.classPersonList = [];
    this.presence1 = this.initClassSection(0, '1');
    this.presence2 = this.initClassSection(0, '2');
    this.presence3 = this.initClassSection(0, '3');
    this.presence7 = this.initClassSection(0, '4');
    this.presence9 = this.initClassSection(0, '5');

    this.presence4 = this.initClassSection(1, '1');
    this.presence5 = this.initClassSection(1, '2');
    this.presence6 = this.initClassSection(1, '3');
    this.presence8 = this.initClassSection(1, '4');
    this.presence10 = this.initClassSection(1, '5');

    this.section1 = { key: moment().format('YYYY-MM-DD') + '-' + '1', id: '' };
    this.section2 = { key: moment().format('YYYY-MM-DD') + '-' + '2', id: '' };
    this.section3 = { key: moment().format('YYYY-MM-DD') + '-' + '3', id: '' };
    this.section7 = { key: moment().format('YYYY-MM-DD') + '-' + '4', id: '' };
    this.section9 = { key: moment().format('YYYY-MM-DD') + '-' + '5', id: '' };

    this.section4 = {
      key:
        moment()
          .subtract(1, 'days')
          .format('YYYY-MM-DD') +
        '-' +
        '1',
      id: ''
    };
    this.section5 = {
      key:
        moment()
          .subtract(1, 'days')
          .format('YYYY-MM-DD') +
        '-' +
        '2',
      id: ''
    };
    this.section6 = {
      key:
        moment()
          .subtract(1, 'days')
          .format('YYYY-MM-DD') +
        '-' +
        '3',
      id: ''
    };
    this.section8 = {
      key:
        moment()
          .subtract(1, 'days')
          .format('YYYY-MM-DD') +
        '-' +
        '4',
      id: ''
    };
    this.section10 = {
      key:
        moment()
          .subtract(1, 'days')
          .format('YYYY-MM-DD') +
        '-' +
        '5',
      id: ''
    };

    this.section1.id = this.section1.key;
    this.section2.id = this.section2.key;
    this.section3.id = this.section3.key;
    this.section4.id = this.section4.key;
    this.section5.id = this.section5.key;
    this.section6.id = this.section6.key;
    this.section7.id = this.section7.key;
    this.section8.id = this.section8.key;
    this.section9.id = this.section9.key;
    this.section10.id = this.section10.key;
  }
  ngOnInit() {
    const that = this;
    that.ds
      .getClassPersonList()
      .child(this.classId)
      .on(
        'child_added',
        (snapshot) => {
          const classperson = snapshot.val();

          that.ds
            .getPersonList()
            .child(snapshot.key)
            .once(
              'value',
              function(snapshot2) {
                // snapshot.forEach(snap => {
                const object = snapshot2.val();
                object.id = snapshot2.key;
                object.sections = {};
                object.foreign = { calculation: { unexcused: 0, unexcusedDate: [], attendance: 0, absent: 0 } };
                object.foreign.classPersonActive = this.active; // from data context
                object.foreign.classPersonDroppedout = this.droppedout; // from data context

                if (object.foreign.classPersonActive === true) {
                  that.classPersonList.push(object);
                }
                that.dataSource.data = that.classPersonList;
                // });

                // Sections pro Person, filter nach Klasse
                that.ds
                  .getPersonPresenceList(snapshot.key)
                  .orderByChild('class')
                  .equalTo(that.classId)
                  .on(
                    'child_added',
                    (snapshot3) => {
                      // snapshot3.forEach(snapshot4 => {
                      const object = snapshot3.val();
                      object.id = snapshot3.key;
                      if (that.classPersonList.map((x) => x.id).indexOf(object.person) > -1) {
                        // wegen Filter sind nicht mehr alle personen in der Liste

                        // object.key = object.date+'-'+object.part;
                        if (object.presence === '4') {
                          // nur eine Unentschuldigte Absenz wenn mehrere pro object.date
                          if (
                            that.classPersonList[
                              that.classPersonList.map((x) => x.id).indexOf(object.person)
                            ].foreign.calculation.unexcusedDate
                              .map((x) => x)
                              .indexOf(object.date) === -1
                          ) {
                            that.classPersonList[
                              that.classPersonList.map((x) => x.id).indexOf(object.person)
                            ].foreign.calculation.unexcused += 1;
                            that.classPersonList[
                              that.classPersonList.map((x) => x.id).indexOf(object.person)
                            ].foreign.calculation.unexcusedDate.push(object.date);
                          }
                        }
                        if (object.presence === '4' || object.presence === '1') {
                          that.classPersonList[
                            that.classPersonList.map((x) => x.id).indexOf(object.person)
                          ].foreign.calculation.absent += 1;
                        }
                        that.classPersonList[that.classPersonList.map((x) => x.id).indexOf(object.person)].sections[
                          object.key
                        ] = object;

                        if (
                          Object.keys(
                            that.classPersonList[that.classPersonList.map((x) => x.id).indexOf(object.person)].sections
                          ).length > 0
                        ) {
                          that.classPersonList[
                            that.classPersonList.map((x) => x.id).indexOf(object.person)
                          ].foreign.calculation.attendance =
                            (100 /
                              Object.keys(
                                that.classPersonList[that.classPersonList.map((x) => x.id).indexOf(object.person)]
                                  .sections
                              ).length) *
                            (Object.keys(
                              that.classPersonList[that.classPersonList.map((x) => x.id).indexOf(object.person)]
                                .sections
                            ).length -
                              that.classPersonList[that.classPersonList.map((x) => x.id).indexOf(object.person)].foreign
                                .calculation.absent);

                          that.classPersonList[
                            that.classPersonList.map((x) => x.id).indexOf(object.person)
                          ].foreign.calculation.attendance =
                            Math.round(
                              that.classPersonList[that.classPersonList.map((x) => x.id).indexOf(object.person)].foreign
                                .calculation.attendance * 10
                            ) / 10;
                        } else {
                          that.classPersonList[
                            that.classPersonList.map((x) => x.id).indexOf(object.person)
                          ].foreign.calculation.attendance = 100;
                        }
                      }

                      // that.classPersonList.push(object);
                      // });
                    },
                    function(err) {
                      that.ms.alertError('Fehler: ' + (err as any).code, err.message);
                    }
                  ); // context dDATA);

                that.ds
                  .getPersonPresenceList(snapshot.key)
                  .orderByChild('class')
                  .equalTo(that.classId)
                  .on(
                    'child_changed',
                    (snapshot3) => {
                      // snapshot3.forEach(snapshot4 => {
                      const object = snapshot3.val();
                      object.id = snapshot3.key;
                      // object.key = object.date+'-'+object.part;
                      if (object.presence === '4') {
                        if (
                          that.classPersonList[
                            that.classPersonList.map((x) => x.id).indexOf(object.person)
                          ].foreign.calculation.unexcusedDate
                            .map((x) => x)
                            .indexOf(object.date) === -1
                        ) {
                          that.classPersonList[
                            that.classPersonList.map((x) => x.id).indexOf(object.person)
                          ].foreign.calculation.unexcused += 1;
                          that.classPersonList[
                            that.classPersonList.map((x) => x.id).indexOf(object.person)
                          ].foreign.calculation.unexcusedDate.push(object.date);
                        }
                      }
                      if (
                        that.classPersonList[that.classPersonList.map((x) => x.id).indexOf(object.person)].sections[
                          object.key
                        ].presence === '4'
                      ) {
                        that.classPersonList[
                          that.classPersonList.map((x) => x.id).indexOf(object.person)
                        ].foreign.calculation.unexcused -= 1;
                      }

                      if (object.presence === '4' || object.presence === '1') {
                        that.classPersonList[
                          that.classPersonList.map((x) => x.id).indexOf(object.person)
                        ].foreign.calculation.absent += 1;
                      }
                      if (
                        that.classPersonList[that.classPersonList.map((x) => x.id).indexOf(object.person)].sections[
                          object.key
                        ].presence === '4' ||
                        that.classPersonList[that.classPersonList.map((x) => x.id).indexOf(object.person)].sections[
                          object.key
                        ].presence === '1'
                      ) {
                        that.classPersonList[
                          that.classPersonList.map((x) => x.id).indexOf(object.person)
                        ].foreign.calculation.absent -= 1;
                      }

                      that.classPersonList[that.classPersonList.map((x) => x.id).indexOf(object.person)].sections[
                        object.key
                      ] = object;

                      if (
                        Object.keys(
                          that.classPersonList[that.classPersonList.map((x) => x.id).indexOf(object.person)].sections
                        ).length > 0
                      ) {
                        that.classPersonList[
                          that.classPersonList.map((x) => x.id).indexOf(object.person)
                        ].foreign.calculation.attendance =
                          (100 /
                            Object.keys(
                              that.classPersonList[that.classPersonList.map((x) => x.id).indexOf(object.person)]
                                .sections
                            ).length) *
                          (Object.keys(
                            that.classPersonList[that.classPersonList.map((x) => x.id).indexOf(object.person)].sections
                          ).length -
                            that.classPersonList[that.classPersonList.map((x) => x.id).indexOf(object.person)].foreign
                              .calculation.absent);

                        that.classPersonList[
                          that.classPersonList.map((x) => x.id).indexOf(object.person)
                        ].foreign.calculation.attendance =
                          Math.round(
                            that.classPersonList[that.classPersonList.map((x) => x.id).indexOf(object.person)].foreign
                              .calculation.attendance * 10
                          ) / 10;
                      } else {
                        that.classPersonList[
                          that.classPersonList.map((x) => x.id).indexOf(object.person)
                        ].foreign.calculation.attendance = 100;
                      }
                      // });
                    },
                    function(err) {
                      that.ms.alertError('Fehler: ' + (err as any).code, err.message);
                    }
                  ); // context dDATA);

                that.ds
                  .getPersonPresenceList(snapshot.key)
                  .orderByChild('class')
                  .equalTo(that.classId)
                  .on(
                    'child_removed',
                    (snapshot3) => {
                      // snapshot3.forEach(snapshot4 => {
                      const object = snapshot3.val();
                      object.id = snapshot3.key;
                      delete that.classPersonList[that.classPersonList.map((x) => x.id).indexOf(object.person)]
                        .sections[object.key];
                      // });
                    },
                    (err) => {
                      that.ms.alertError('Fehler: ' + (err as any).code, err.message);
                    }
                  ); // context dDATA);
              },
              (err) => {
                that.ms.alertError('Fehler: ' + (err as any).code, err.message);
              },
              classperson
            );
        },
        (err) => {
          that.ms.alertError('Fehler: ' + (err as any).code, err.message);
        }
      );
  }
  gotoPerson(item) {
    this.router.navigate(['/bildung/person', item.id]);
  }

  saveClassSection(days, part) {
    const that = this;

    const newItem = this.initClassSection(days, part);
    newItem.key = newItem.date + '-' + newItem.part;
    newItem.class = this.classId;

    const classsection = newItem;
    classsection.members = [];
    this.classPersonList.forEach((snap) => {
      if (snap.foreign.classPersonActive !== true) {
        return;
      }
      classsection.members[snap.id] = true;
    });

    this.ds
      .createClassSection(this.classId, newItem)
      .then(function(value) {
        that.classPersonList.forEach((snap) => {
          if (snap.foreign.classPersonActive !== true) {
            return;
          }
          const personpresence = newItem;
          personpresence.presence = '';
          personpresence.person = snap.id;
          personpresence.members = null;

          that.ds
            .createPersonPresence(snap.id, personpresence)
            .then((value) => {})
            .catch((err) => {
              that.ms.alertError('Fehler: ' + (err as any).code, err.message);
            });
        });

        that.ms.alertSuccess('Hinzugefügt', 'Das Item wurde erfolgreich hinzugefügt.');
      })
      .catch((err) => {
        that.ms.alertError('Fehler: ' + (err as any).code, err.message);
      });
  }
  deleteClassSection(days, part) {
    const that = this;

    const newItem = this.initClassSection(days, part);
    newItem.key = newItem.date + '-' + newItem.part;
    // newItem.class = this.classId;

    this.ds
      .deleteClassSection(this.classId, newItem.key)
      .then((value) => {})
      .catch((err) => {
        that.ms.alertError('Fehler: ' + (err as any).code, err.message);
      });

    this.classPersonList.forEach((snap) => {
      // if (snap.foreign.classPersonActive !== true){return;}

      that.ds
        .deletePersonPresence(snap.id, newItem.key)
        .then((value) => {})
        .catch((err) => {
          that.ms.alertError('Fehler: ' + (err as any).code, err.message);
        });
    });
  }

  updatePersonPresence(item, section, value) {
    const that = this;

    this.ds
      .updatePersonPresence(item.id, section, value)
      .then((value) => {
        that.ms.alertSuccess('Geändert', 'Das Item wurde erfolgreich geändert.');
      })
      .catch((err) => {});
  }

  initClassSection(days, part): any {
    const section = {
      date: '',
      part,
      key: ''
    };
    section.date = moment()
      .subtract(days, 'days')
      .format('YYYY-MM-DD');
    return section;
  }
}

@Component({
    selector: 'persons',
    templateUrl: './person.component.html',
    styleUrls: ['./home.component.css'],
    standalone: true
})
export class subComponentPersons {
  @Input() person: any;

  // public person: any;

  public doughnutChartLabels: string[] = ['Anwesend', 'Verspätet', 'Entschuldigt', 'Unentschuldigt'];
  public doughnutChartData: number[] = [10, 5, 2, 3];
  public doughnutChartType = 'doughnut';
  public lineChartLegend = false;
  public chartoptions: any = {
    responsive: true
  };

  constructor(public ds: DataService) {
    // this.person = {};
  }
  ngOnInit() {
    const that = this;
  }
}

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css'],
    standalone: true,
    imports: [subComponentClassPersons, AnalyticsPresenceComponent, subComponentOrders, AsyncPipe]
})
export class HomeComponent implements OnInit {
  public classList: any;
  public personList: any;
  public customerList: Observable<any[]>;

  constructor(
    public ds: DataService,
    private as: AuthService,
    private router: Router,
    // private auth: Auth
    public fs: FirestoreService
  ) {
    this.classList = [];
    this.personList = [];
  }

  ngOnInit() {
    const that = this;
    // this.as.onAuthStateChanged()
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        that.ds
          .getClassList()
          .orderByChild('yearsemester')
          .equalTo(that.ds.selectedYearSemester)
          .on(
            'value',
            (snapshot) => {
              snapshot.forEach((snapshot) => {
                const object = snapshot.val();
                object.id = snapshot.key;
                if (
                  object.teacher === that.as.getCurrentUser().uid ||
                  object.teacher2 === that.as.getCurrentUser().uid ||
                  object.teacher3 === that.as.getCurrentUser().uid ||
                  object.teacher4 === that.as.getCurrentUser().uid ||
                  object.teacher5 === that.as.getCurrentUser().uid ||
                  object.teacher6 === that.as.getCurrentUser().uid
                ) {
                  that.classList.push(object);
                }
              });
            },
            (err) => {
              // that.ms.alertError('Fehler: ' + (err as any).code, err.message );
            }
          );
        that.ds
          .getPersonList()
          .orderByChild('supervisor')
          .equalTo(user.uid)
          .on(
            'value',
            (snapshot) => {
              snapshot.forEach((snapshot) => {
                const object = snapshot.val();
                object.id = snapshot.key;
                that.personList.push(object);
              });
            },
            (err) => {
              // that.ms.alertError('Fehler: ' + (err as any).code, err.message );
            }
          );
        this.customerList = this.fs.getCustomerListOfSupervisor(user.email);
      }
    });
  }
  gotoClass(item) {
    this.router.navigate(['/bildung/class', item.id, 'members']);
  }
  gotoPerson(item) {
    this.router.navigate(['/person', item.id]);
  }
}
