<mat-table [dataSource]="dataSource">
  <!-- Position Column -->
  <ng-container matColumnDef="firstname">
    <mat-header-cell *matHeaderCellDef> Vorname </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{ element.firstname }} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="lastname">
    <mat-header-cell *matHeaderCellDef> Nachname </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{ element.lastname }} </mat-cell>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{ element.firstname }} {{ element.lastname }} </mat-cell>
  </ng-container>

  <!-- Weight Column -->
  <ng-container matColumnDef="unexcused">
    <mat-header-cell *matHeaderCellDef> Unentschuldigte Absenzen </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{ element.foreign.calculation.unexcused }} </mat-cell>
  </ng-container>

  Symbol Column -->
  <ng-container matColumnDef="attendance">
    <mat-header-cell *matHeaderCellDef> Anwesenheit in % </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{ element.foreign.calculation.attendance }} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="section1">
    <mat-header-cell *matHeaderCellDef [ngClass]="'actioncol'">
      Präsenz <br />
      {{ presence1.date | date: 'dd.MM' }} <br />
      {{ presence1.part | presencePart }} <br />
      <button mat-icon-button color="warn" (click)="deleteClassSection(0, '1')"><mat-icon>delete</mat-icon></button>
      <button mat-icon-button color="primary" (click)="saveClassSection(0, '1')">
        <mat-icon>control_point</mat-icon>
      </button>
    </mat-header-cell>
    <mat-cell *matCellDef="let element" [ngClass]="'actioncol'">
      @if (element.sections[section1.key] && element.sections[section1.key].presence == '') {
        <button
          mat-icon-button
          (click)="updatePersonPresence(element, section1, '1')"
          >
          <mat-icon color="">help_outline</mat-icon>
        </button>
      }
      @if (element.sections[section1.key] && element.sections[section1.key].presence == '1') {
        <button
          mat-icon-button
          (click)="updatePersonPresence(element, section1, '2')"
          >
          <mat-icon color="primary">call</mat-icon>
        </button>
      }
      @if (element.sections[section1.key] && element.sections[section1.key].presence == '2') {
        <button
          mat-icon-button
          (click)="updatePersonPresence(element, section1, '3')"
          >
          <mat-icon color="primary">done</mat-icon>
        </button>
      }
      @if (element.sections[section1.key] && element.sections[section1.key].presence == '3') {
        <button
          mat-icon-button
          (click)="updatePersonPresence(element, section1, '4')"
          >
          <mat-icon color="accent">av_timer</mat-icon>
        </button>
      }
      @if (element.sections[section1.key] && element.sections[section1.key].presence == '4') {
        <button
          mat-icon-button
          (click)="updatePersonPresence(element, section1, '1')"
          >
          <mat-icon color="warn">clear</mat-icon>
        </button>
      }
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="section7">
    <mat-header-cell *matHeaderCellDef [ngClass]="'actioncol'">
      Präsenz <br />
      {{ presence7.date | date: 'dd.MM' }} <br />
      {{ presence7.part | presencePart }} <br />
      <button mat-icon-button color="warn" (click)="deleteClassSection(0, '4')"><mat-icon>delete</mat-icon></button>
      <button mat-icon-button color="primary" (click)="saveClassSection(0, '4')">
        <mat-icon>control_point</mat-icon>
      </button>
    </mat-header-cell>
    <mat-cell *matCellDef="let element" [ngClass]="'actioncol'">
      @if (element.sections[section7.key] && element.sections[section7.key].presence == '') {
        <button
          mat-icon-button
          (click)="updatePersonPresence(element, section7, '1')"
          >
          <mat-icon color="">help_outline</mat-icon>
        </button>
      }
      @if (element.sections[section7.key] && element.sections[section7.key].presence == '1') {
        <button
          mat-icon-button
          (click)="updatePersonPresence(element, section7, '2')"
          >
          <mat-icon color="primary">call</mat-icon>
        </button>
      }
      @if (element.sections[section7.key] && element.sections[section7.key].presence == '2') {
        <button
          mat-icon-button
          (click)="updatePersonPresence(element, section7, '3')"
          >
          <mat-icon color="primary">done</mat-icon>
        </button>
      }
      @if (element.sections[section7.key] && element.sections[section7.key].presence == '3') {
        <button
          mat-icon-button
          (click)="updatePersonPresence(element, section7, '4')"
          >
          <mat-icon color="accent">av_timer</mat-icon>
        </button>
      }
      @if (element.sections[section7.key] && element.sections[section7.key].presence == '4') {
        <button
          mat-icon-button
          (click)="updatePersonPresence(element, section7, '1')"
          >
          <mat-icon color="warn">clear</mat-icon>
        </button>
      }
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="section2">
    <mat-header-cell *matHeaderCellDef [ngClass]="'actioncol'">
      Präsenz <br />
      {{ presence2.date | date: 'dd.MM' }} <br />
      {{ presence2.part | presencePart }} <br />
      <button mat-icon-button color="warn" (click)="deleteClassSection(0, '2')"><mat-icon>delete</mat-icon></button>
      <button mat-icon-button color="primary" (click)="saveClassSection(0, '2')">
        <mat-icon>control_point</mat-icon>
      </button>
    </mat-header-cell>
    <mat-cell *matCellDef="let element" [ngClass]="'actioncol'">
      @if (element.sections[section2.key] && element.sections[section2.key].presence == '') {
        <button
          mat-icon-button
          (click)="updatePersonPresence(element, section2, '1')"
          >
          <mat-icon color="">help_outline</mat-icon>
        </button>
      }
      @if (element.sections[section2.key] && element.sections[section2.key].presence == '1') {
        <button
          mat-icon-button
          (click)="updatePersonPresence(element, section2, '2')"
          >
          <mat-icon color="primary">call</mat-icon>
        </button>
      }
      @if (element.sections[section2.key] && element.sections[section2.key].presence == '2') {
        <button
          mat-icon-button
          (click)="updatePersonPresence(element, section2, '3')"
          >
          <mat-icon color="primary">done</mat-icon>
        </button>
      }
      @if (element.sections[section2.key] && element.sections[section2.key].presence == '3') {
        <button
          mat-icon-button
          (click)="updatePersonPresence(element, section2, '4')"
          >
          <mat-icon color="accent">av_timer</mat-icon>
        </button>
      }
      @if (element.sections[section2.key] && element.sections[section2.key].presence == '4') {
        <button
          mat-icon-button
          (click)="updatePersonPresence(element, section2, '1')"
          >
          <mat-icon color="warn">clear</mat-icon>
        </button>
      }
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="section9">
    <mat-header-cell *matHeaderCellDef [ngClass]="'actioncol'">
      Präsenz <br />
      {{ presence9.date | date: 'dd.MM' }} <br />
      {{ presence9.part | presencePart }} <br />
      <button mat-icon-button color="warn" (click)="deleteClassSection(0, '5')"><mat-icon>delete</mat-icon></button>
      <button mat-icon-button color="primary" (click)="saveClassSection(0, '5')">
        <mat-icon>control_point</mat-icon>
      </button>
    </mat-header-cell>
    <mat-cell *matCellDef="let element" [ngClass]="'actioncol'">
      @if (element.sections[section9.key] && element.sections[section9.key].presence == '') {
        <button
          mat-icon-button
          (click)="updatePersonPresence(element, section9, '1')"
          >
          <mat-icon color="">help_outline</mat-icon>
        </button>
      }
      @if (element.sections[section9.key] && element.sections[section9.key].presence == '1') {
        <button
          mat-icon-button
          (click)="updatePersonPresence(element, section9, '2')"
          >
          <mat-icon color="primary">call</mat-icon>
        </button>
      }
      @if (element.sections[section9.key] && element.sections[section9.key].presence == '2') {
        <button
          mat-icon-button
          (click)="updatePersonPresence(element, section9, '3')"
          >
          <mat-icon color="primary">done</mat-icon>
        </button>
      }
      @if (element.sections[section9.key] && element.sections[section9.key].presence == '3') {
        <button
          mat-icon-button
          (click)="updatePersonPresence(element, section9, '4')"
          >
          <mat-icon color="accent">av_timer</mat-icon>
        </button>
      }
      @if (element.sections[section9.key] && element.sections[section9.key].presence == '4') {
        <button
          mat-icon-button
          (click)="updatePersonPresence(element, section9, '1')"
          >
          <mat-icon color="warn">clear</mat-icon>
        </button>
      }
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="section3">
    <mat-header-cell *matHeaderCellDef [ngClass]="'actioncol'">
      Präsenz <br />
      {{ presence3.date | date: 'dd.MM' }} <br />
      {{ presence3.part | presencePart }} <br />
      <button mat-icon-button color="warn" (click)="deleteClassSection(0, '3')"><mat-icon>delete</mat-icon></button>
      <button mat-icon-button color="primary" (click)="saveClassSection(0, '3')">
        <mat-icon>control_point</mat-icon>
      </button>
    </mat-header-cell>
    <mat-cell *matCellDef="let element" [ngClass]="'actioncol'">
      @if (element.sections[section3.key] && element.sections[section3.key].presence == '') {
        <button
          mat-icon-button
          (click)="updatePersonPresence(element, section3, '1')"
          >
          <mat-icon color="">help_outline</mat-icon>
        </button>
      }
      @if (element.sections[section3.key] && element.sections[section3.key].presence == '1') {
        <button
          mat-icon-button
          (click)="updatePersonPresence(element, section3, '2')"
          >
          <mat-icon color="primary">call</mat-icon>
        </button>
      }
      @if (element.sections[section3.key] && element.sections[section3.key].presence == '2') {
        <button
          mat-icon-button
          (click)="updatePersonPresence(element, section3, '3')"
          >
          <mat-icon color="primary">done</mat-icon>
        </button>
      }
      @if (element.sections[section3.key] && element.sections[section3.key].presence == '3') {
        <button
          mat-icon-button
          (click)="updatePersonPresence(element, section3, '4')"
          >
          <mat-icon color="accent">av_timer</mat-icon>
        </button>
      }
      @if (element.sections[section3.key] && element.sections[section3.key].presence == '4') {
        <button
          mat-icon-button
          (click)="updatePersonPresence(element, section3, '1')"
          >
          <mat-icon color="warn">clear</mat-icon>
        </button>
      }
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="section4">
    <mat-header-cell *matHeaderCellDef [ngClass]="'actioncol'">
      Präsenz <br />
      {{ presence4.date | date: 'dd.MM' }} <br />
      {{ presence4.part | presencePart }} <br />
      <button mat-icon-button color="warn" (click)="deleteClassSection(1, '1')"><mat-icon>delete</mat-icon></button>
      <button mat-icon-button color="primary" (click)="saveClassSection(1, '1')">
        <mat-icon>control_point</mat-icon>
      </button>
    </mat-header-cell>
    <mat-cell *matCellDef="let element" [ngClass]="'actioncol'">
      @if (element.sections[section4.key] && element.sections[section4.key].presence == '') {
        <button
          mat-icon-button
          (click)="updatePersonPresence(element, section4, '1')"
          >
          <mat-icon color="">help_outline</mat-icon>
        </button>
      }
      @if (element.sections[section4.key] && element.sections[section4.key].presence == '1') {
        <button
          mat-icon-button
          (click)="updatePersonPresence(element, section4, '2')"
          >
          <mat-icon color="primary">call</mat-icon>
        </button>
      }
      @if (element.sections[section4.key] && element.sections[section4.key].presence == '2') {
        <button
          mat-icon-button
          (click)="updatePersonPresence(element, section4, '3')"
          >
          <mat-icon color="primary">done</mat-icon>
        </button>
      }
      @if (element.sections[section4.key] && element.sections[section4.key].presence == '3') {
        <button
          mat-icon-button
          (click)="updatePersonPresence(element, section4, '4')"
          >
          <mat-icon color="accent">av_timer</mat-icon>
        </button>
      }
      @if (element.sections[section4.key] && element.sections[section4.key].presence == '4') {
        <button
          mat-icon-button
          (click)="updatePersonPresence(element, section4, '1')"
          >
          <mat-icon color="warn">clear</mat-icon>
        </button>
      }
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="section8">
    <mat-header-cell *matHeaderCellDef [ngClass]="'actioncol'">
      Präsenz <br />
      {{ presence8.date | date: 'dd.MM' }} <br />
      {{ presence8.part | presencePart }} <br />
      <button mat-icon-button color="warn" (click)="deleteClassSection(0, '4')"><mat-icon>delete</mat-icon></button>
      <button mat-icon-button color="primary" (click)="saveClassSection(0, '4')">
        <mat-icon>control_point</mat-icon>
      </button>
    </mat-header-cell>
    <mat-cell *matCellDef="let element" [ngClass]="'actioncol'">
      @if (element.sections[section8.key] && element.sections[section8.key].presence == '') {
        <button
          mat-icon-button
          (click)="updatePersonPresence(element, section8, '1')"
          >
          <mat-icon color="">help_outline</mat-icon>
        </button>
      }
      @if (element.sections[section8.key] && element.sections[section8.key].presence == '1') {
        <button
          mat-icon-button
          (click)="updatePersonPresence(element, section8, '2')"
          >
          <mat-icon color="primary">call</mat-icon>
        </button>
      }
      @if (element.sections[section8.key] && element.sections[section8.key].presence == '2') {
        <button
          mat-icon-button
          (click)="updatePersonPresence(element, section8, '3')"
          >
          <mat-icon color="primary">done</mat-icon>
        </button>
      }
      @if (element.sections[section8.key] && element.sections[section8.key].presence == '3') {
        <button
          mat-icon-button
          (click)="updatePersonPresence(element, section8, '4')"
          >
          <mat-icon color="accent">av_timer</mat-icon>
        </button>
      }
      @if (element.sections[section8.key] && element.sections[section8.key].presence == '4') {
        <button
          mat-icon-button
          (click)="updatePersonPresence(element, section8, '1')"
          >
          <mat-icon color="warn">clear</mat-icon>
        </button>
      }
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="section5">
    <mat-header-cell *matHeaderCellDef [ngClass]="'actioncol'">
      Präsenz <br />
      {{ presence5.date | date: 'dd.MM' }} <br />
      {{ presence5.part | presencePart }} <br />
      <button mat-icon-button color="warn" (click)="deleteClassSection(1, '2')"><mat-icon>delete</mat-icon></button>
      <button mat-icon-button color="primary" (click)="saveClassSection(1, '2')">
        <mat-icon>control_point</mat-icon>
      </button>
    </mat-header-cell>
    <mat-cell *matCellDef="let element" [ngClass]="'actioncol'">
      @if (element.sections[section5.key] && element.sections[section5.key].presence == '') {
        <button
          mat-icon-button
          (click)="updatePersonPresence(element, section5, '1')"
          >
          <mat-icon color="">help_outline</mat-icon>
        </button>
      }
      @if (element.sections[section5.key] && element.sections[section5.key].presence == '1') {
        <button
          mat-icon-button
          (click)="updatePersonPresence(element, section5, '2')"
          >
          <mat-icon color="primary">call</mat-icon>
        </button>
      }
      @if (element.sections[section5.key] && element.sections[section5.key].presence == '2') {
        <button
          mat-icon-button
          (click)="updatePersonPresence(element, section5, '3')"
          >
          <mat-icon color="primary">done</mat-icon>
        </button>
      }
      @if (element.sections[section5.key] && element.sections[section5.key].presence == '3') {
        <button
          mat-icon-button
          (click)="updatePersonPresence(element, section5, '4')"
          >
          <mat-icon color="accent">av_timer</mat-icon>
        </button>
      }
      @if (element.sections[section5.key] && element.sections[section5.key].presence == '4') {
        <button
          mat-icon-button
          (click)="updatePersonPresence(element, section5, '1')"
          >
          <mat-icon color="warn">clear</mat-icon>
        </button>
      }
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="section10">
    <mat-header-cell *matHeaderCellDef [ngClass]="'actioncol'">
      Präsenz <br />
      {{ presence10.date | date: 'dd.MM' }} <br />
      {{ presence10.part | presencePart }} <br />
      <button mat-icon-button color="warn" (click)="deleteClassSection(1, '5')"><mat-icon>delete</mat-icon></button>
      <button mat-icon-button color="primary" (click)="saveClassSection(1, '5')">
        <mat-icon>control_point</mat-icon>
      </button>
    </mat-header-cell>
    <mat-cell *matCellDef="let element" [ngClass]="'actioncol'">
      @if (element.sections[section10.key] && element.sections[section10.key].presence == '') {
        <button
          mat-icon-button
          (click)="updatePersonPresence(element, section10, '1')"
          >
          <mat-icon color="">help_outline</mat-icon>
        </button>
      }
      @if (element.sections[section10.key] && element.sections[section10.key].presence == '1') {
        <button
          mat-icon-button
          (click)="updatePersonPresence(element, section10, '2')"
          >
          <mat-icon color="primary">call</mat-icon>
        </button>
      }
      @if (element.sections[section10.key] && element.sections[section10.key].presence == '2') {
        <button
          mat-icon-button
          (click)="updatePersonPresence(element, section10, '3')"
          >
          <mat-icon color="primary">done</mat-icon>
        </button>
      }
      @if (element.sections[section10.key] && element.sections[section10.key].presence == '3') {
        <button
          mat-icon-button
          (click)="updatePersonPresence(element, section10, '4')"
          >
          <mat-icon color="accent">av_timer</mat-icon>
        </button>
      }
      @if (element.sections[section10.key] && element.sections[section10.key].presence == '4') {
        <button
          mat-icon-button
          (click)="updatePersonPresence(element, section10, '1')"
          >
          <mat-icon color="warn">clear</mat-icon>
        </button>
      }
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="section6">
    <mat-header-cell *matHeaderCellDef [ngClass]="'actioncol'">
      Präsenz <br />
      {{ presence6.date | date: 'dd.MM' }} <br />
      {{ presence6.part | presencePart }} <br />
      <button mat-icon-button color="warn" (click)="deleteClassSection(1, '3')"><mat-icon>delete</mat-icon></button>
      <button mat-icon-button color="primary" (click)="saveClassSection(1, '3')">
        <mat-icon>control_point</mat-icon>
      </button>
    </mat-header-cell>
    <mat-cell *matCellDef="let element" [ngClass]="'actioncol'">
      @if (element.sections[section6.key] && element.sections[section6.key].presence == '') {
        <button
          mat-icon-button
          (click)="updatePersonPresence(element, section6, '1')"
          >
          <mat-icon color="">help_outline</mat-icon>
        </button>
      }
      @if (element.sections[section6.key] && element.sections[section6.key].presence == '1') {
        <button
          mat-icon-button
          (click)="updatePersonPresence(element, section6, '2')"
          >
          <mat-icon color="primary">call</mat-icon>
        </button>
      }
      @if (element.sections[section6.key] && element.sections[section6.key].presence == '2') {
        <button
          mat-icon-button
          (click)="updatePersonPresence(element, section6, '3')"
          >
          <mat-icon color="primary">done</mat-icon>
        </button>
      }
      @if (element.sections[section6.key] && element.sections[section6.key].presence == '3') {
        <button
          mat-icon-button
          (click)="updatePersonPresence(element, section6, '4')"
          >
          <mat-icon color="accent">av_timer</mat-icon>
        </button>
      }
      @if (element.sections[section6.key] && element.sections[section6.key].presence == '4') {
        <button
          mat-icon-button
          (click)="updatePersonPresence(element, section6, '1')"
          >
          <mat-icon color="warn">clear</mat-icon>
        </button>
      }
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="gotoperson">
    <mat-header-cell *matHeaderCellDef [ngClass]="'actioncol'"> Anzeigen </mat-header-cell>
    <mat-cell *matCellDef="let element" [ngClass]="'actioncol'">
      <button mat-icon-button color="primary" (click)="gotoPerson(element)"><mat-icon>arrow_forward</mat-icon></button>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row
    [ngClass]="{ warning: !row.foreign.classPersonActive, danger: row.foreign.classPersonDroppedout }"
    *matRowDef="let row; columns: displayedColumns"
  ></mat-row>
</mat-table>
