<section class="container-fluid padding-top-5x">
  <h1 class="page-header">Dolmetsch Auftrag anzeigen</h1>

  <div class="example-container">
    <mat-form-field>
      <mat-select placeholder="Kunde" disabled [(ngModel)]="newItem.customerId">
        @for (item of customers; track item) {
          <mat-option [value]="item.id">{{ item.name }}</mat-option>
        }
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-select placeholder="Kategorie" disabled [(ngModel)]="newItem.dolmetschCategoryId">
        @for (item of dolmetschCategories | async; track item) {
          <mat-option [value]="item.id">{{ item.title }}</mat-option>
        }
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-select placeholder="Art" disabled [(ngModel)]="newItem.dolmetschTypeId">
        @for (item of dolmetschTypes | async; track item) {
          <mat-option [value]="item.id">{{ item.title }}</mat-option>
        }
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-select placeholder="Region" disabled [(ngModel)]="newItem.regionId">
        @for (item of regions | async; track item) {
          <mat-option [value]="item.id">{{ item.title }}</mat-option>
        }
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-select placeholder="Sprache" disabled [(ngModel)]="newItem.languageId">
        @for (item of languages | async; track item) {
          <mat-option [value]="item.id">{{ item.language }}</mat-option>
        }
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <input matInput placeholder="Strasse" disabled [(ngModel)]="newItem.street" />
    </mat-form-field>
    <mat-form-field>
      <input matInput placeholder="PLZ" disabled [(ngModel)]="newItem.postalCode" />
    </mat-form-field>
    <mat-form-field>
      <input matInput placeholder="City" disabled [(ngModel)]="newItem.city" />
    </mat-form-field>

    <br />
    <h3>Dolmetscher</h3>
    <mat-form-field>
      <mat-select placeholder="Dolmetscher" disabled [(ngModel)]="newItem.dolmetscherId">
        <mat-option value=""></mat-option>
        @for (item of dolmetschers; track item) {
          <mat-option [value]="item.id"
            >{{ item.firstname }} {{ item.lastname }}</mat-option
            >
          }
        </mat-select>
      </mat-form-field>
      <br />
      <br />
      <h3>Kontaktdaten Auftraggeber</h3>
      <mat-form-field>
        <input matInput placeholder="Vorname" disabled [(ngModel)]="newItem.contactFirstname" />
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="Nachname" disabled [(ngModel)]="newItem.contactLastname" />
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="eMail" disabled [(ngModel)]="newItem.contactEmail" />
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="Telefon" disabled [(ngModel)]="newItem.contactTelefon" />
      </mat-form-field>
      <br />
      <br />
      <h3>Angaben zum Auftrag</h3>

      <mat-form-field>
        <input matInput placeholder="Verfahrensschritt" disabled [(ngModel)]="newItem.step" />
      </mat-form-field>
      <!-- <mat-form-field>
      <input matInput placeholder="RBS Bern: Kategorie" disabled [(ngModel)]="newItem.rbsberncategory"  >
    </mat-form-field>
    <mat-form-field>
      <input matInput placeholder="RBS Bern: Sprachen" disabled [(ngModel)]="newItem.gs_language"  >
    </mat-form-field> -->
    <!-- <mat-form-field>
    <input matInput placeholder="GS Nr." disabled [(ngModel)]="newItem.gs_nr"  >
  </mat-form-field>
  <mat-form-field>
    <input matInput placeholder="Z NR." disabled [(ngModel)]="newItem.gs_znr"  >
  </mat-form-field> -->
  <mat-form-field>
    <input matInput placeholder="Name Klient" disabled [(ngModel)]="newItem.nameendcustomer" />
  </mat-form-field>
  <!-- <mat-form-field>
  <input matInput placeholder="Technische Referenz" disabled [(ngModel)]="newItem.rbsbernpk" disabled >
</mat-form-field> -->
<mat-checkbox matCheckbox disabled [(ngModel)]="newItem.extraSheet">Zusatzblatt benötigt</mat-checkbox>

<br />
<br />
@if (isWrittenTranslation() != true) {
  <h3>Zeiten</h3>
}
@if (isWrittenTranslation() == true) {
  <h3>Schriftliche Übersetzung</h3>
}

@if (isWrittenTranslation() != true) {
  <mat-form-field>
    <input
      matInput
      [matDatepicker]="picker"
      disabled
      placeholder="Start Datum"
      displayFormat="DD.MM.YYYY HH:mm"
      pickerFormat="DD.MM.YYYY HH:mm"
      minuteValues="0,15,30,45"
      [(ngModel)]="newItem.dateFrom"
      />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  }
  @if (isWrittenTranslation() != true) {
    <mat-form-field>
      <input type="time" matInput disabled placeholder="Start Uhrzeit" step="300" [(ngModel)]="newItem.dateFromTime" />
    </mat-form-field>
  }
  <mat-form-field>
    <input matInput [matDatepicker]="picker2" disabled placeholder="End Datum" [(ngModel)]="newItem.dateTo" />
    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
    <mat-datepicker #picker2></mat-datepicker>
  </mat-form-field>
  @if (isWrittenTranslation() != true) {
    <mat-form-field>
      <input type="time" matInput disabled placeholder="End Uhrzeit" step="300" [(ngModel)]="newItem.dateToTime" />
    </mat-form-field>
  }

  <br />
  @if (isWrittenTranslation() != true) {
    <mat-form-field>
      <input
        matInput
        disabled
        placeholder="Dolmetschzeit"
        maxlength="5"
        minlength="5"
        [(ngModel)]="newItem.durationDolmetsch"
        />
      </mat-form-field>
    }
    @if (isTrialog()) {
      <mat-form-field>
        <input matInput disabled placeholder="Telefongespräch" step="300" [(ngModel)]="newItem.durationCall" />
      </mat-form-field>
    }
    @if (isWrittenTranslation() != true) {
      <mat-form-field>
        <input
          matInput
          disabled
          placeholder="Reisezeit"
          maxlength="5"
          minlength="5"
          [(ngModel)]="newItem.durationTravel"
          />
        </mat-form-field>
      }
      @if (isWrittenTranslation() != true) {
        <mat-form-field>
          <input matInput disabled placeholder="Wartezeit" maxlength="5" minlength="5" [(ngModel)]="newItem.durationWait" />
        </mat-form-field>
      }

      @if (isWrittenTranslation() == true) {
        <mat-form-field>
          <input matInput disabled matInput placeholder="Anzahl Seiten" [(ngModel)]="newItem.numberOfPages" />
        </mat-form-field>
      }

      @if (isWrittenTranslation() == true) {
        <mat-form-field>
          <input matInput disabled placeholder="Schwierigkeitsgrad" [(ngModel)]="newItem.difficlty" />
        </mat-form-field>
      }

      <br />
      @if (isWrittenTranslation() != true) {
        <mat-checkbox disabled [(ngModel)]="newItem.noshow"
          >Kunde ist nicht zum Termin erschienen</mat-checkbox
          >
      }
      <br />

      <br />
      <h3>Spesen</h3>
      <mat-form-field>
        <input
          matInput
          type="number"
          disabled
          step="0.01"
          placeholder="Reisespesen"
          [(ngModel)]="newItem.expenseTravel"
          />
        </mat-form-field>

        <br />
        <h3>Status</h3>
        <mat-checkbox [(ngModel)]="newItem.statusAssigned" disabled>An Dolmetscher zugewiesen</mat-checkbox> <br />
        <mat-checkbox [(ngModel)]="newItem.statusCleared" disabled>Auftrag von Dolmetscher bestätigt</mat-checkbox> <br />
        <mat-checkbox [(ngModel)]="newItem.statusConfirmed" disabled
          >Autrag durch SAH zur Verrechnung freigegeben
        </mat-checkbox>
        <br />
      </div>

      <h2>Anfragen an Dolmetscher</h2>

      <div class="example-container mat-elevation-z8">
        <div class="example-header"></div>

        <mat-table #table [dataSource]="dataSource" matSort>
          <ng-container matColumnDef="lastname">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Nachname </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ (element.dolmetscher | async)?.lastname }} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="firstname">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Nachname </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ (element.dolmetscher | async)?.firstname }} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="email">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Nachname </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ (element.dolmetscher | async)?.email }} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef [ngClass]="'actioncol'"> Status </mat-header-cell>
            <mat-cell *matCellDef="let element" [ngClass]="'actioncol'">
              @if (element.status == 'O') {
                <mat-icon color="status-done" tooltip="Angefragt">send</mat-icon>
              }
              @if (element.status == 'A') {
                <mat-icon color="status-done" tooltip="Angenommen">check_circle</mat-icon>
              }
              @if (element.status == 'C') {
                <mat-icon color="status-open" tooltip="Zurückgezogen">undo</mat-icon>
              }
              @if (element.status == 'D') {
                <mat-icon color="status-open" tooltip="Abgelehnt">backspace</mat-icon>
              }
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
      </div>
    </section>
