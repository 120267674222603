<!--Person: {{person.firstname}} {{person.lastname}}-->
<h3>Präsenz</h3>
<div style="display: block; max-width: 300px;">
  <!--<canvas baseChart 
              [data]="doughnutChartData"
              [labels]="doughnutChartLabels"
              [chartType]="doughnutChartType"
              [legend]="lineChartLegend"
              [options]="chartOptions"></canvas>-->
</div>
<!--
<div class="container dynamicTile">
	<div class="row ">

		<div class="row">
			<div class="col-sm-4 col-xs-8">
				<div id="tile7" class="tile">
					
					<div class="carousel slide" data-ride="carousel">

						<div class="carousel-inner">
							<div class="item active">
								<img src="http://handsontek.net/demoimages/tiles/gallery.png" class="img-responsive"/>
							</div>
							<div class="item">
								<img src="http://handsontek.net/demoimages/tiles/gallery2.png" class="img-responsive"/>
							</div>
							<div class="item">
								<img src="http://handsontek.net/demoimages/tiles/gallery3.png" class="img-responsive"/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div> -->
