/* src/app/home/order-display/order-display.component.css */
.actioncol {
  flex: 0 0 55px;
}
.example-container {
  display: flex;
  flex-direction: column;
}
.example-container > * {
  width: 100%;
}
/*# sourceMappingURL=order-display.component.css.map */
