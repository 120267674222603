import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import { MatTableDataSource, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { FirestoreService } from '../../firestore.service';
import { MatIcon } from '@angular/material/icon';
import { NgClass, AsyncPipe } from '@angular/common';
import { MatDatepickerInput, MatDatepickerToggle, MatDatepicker } from '@angular/material/datepicker';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatInput } from '@angular/material/input';
import { MatOption } from '@angular/material/core';
import { FormsModule } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { MatFormField, MatSuffix } from '@angular/material/form-field';

@Component({
    selector: 'app-order-display',
    templateUrl: './order-display.component.html',
    styleUrls: ['./order-display.component.css'],
    standalone: true,
    imports: [MatFormField, MatSelect, FormsModule, MatOption, MatInput, MatCheckbox, MatDatepickerInput, MatDatepickerToggle, MatSuffix, MatDatepicker, MatTable, MatSort, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatSortHeader, MatCellDef, MatCell, NgClass, MatIcon, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, AsyncPipe]
})
export class OrderDisplayComponent implements OnInit {
  @ViewChild(MatSort, { static: false }) materialSort: MatSort;

  displayedColumns = ['lastname', 'firstname', 'email', 'status'];

  newItem: any;
  dolmetschTypes: any = [];
  dolmetschCategories: any = [];
  languages: any = [];
  regions: any = [];
  dolmetschers: any = [];
  customers: any = [];
  id: any;
  filter: any;
  sort: any;

  requestList: any = [];

  dataSource = new MatTableDataSource(this.requestList);
  dataSubsubscription: any;
  dataSubsubscription2: any;
  dataSubsubscription3: any;
  dataSubsubscription4: any;

  constructor(private fs: FirestoreService, private route: ActivatedRoute, private router: Router) {
    this.newItem = {};

    this.sort = { value: '', direction: '', sorter: [] };
  }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');

    this.dolmetschTypes = this.fs.getDolmetschTypeList();
    this.dolmetschCategories = this.fs.getDolmetschCategoryList();
    this.languages = this.fs.getLanguageList();

    this.regions = this.fs.getRegionList();
    // this.dolmetschers = this.fs.getDolmetscherList()
    this.dataSubsubscription2 = this.fs.getCustomerList().subscribe((data) => {
      this.customers = data;
    });
    this.doSearch();
  }
  isWrittenTranslation() {
    if (this.newItem.dolmetschTypeId == '9Q88e2frktuGSXxuPbi9') {
      return true;
    }
  }
  isTrialog() {
    if (this.newItem.dolmetschTypeId == 'KLKoilDYfFKpmZV35H7q') {
      return true;
    }
  }

  doSearch() {
    this.dataSubsubscription = this.fs.getOrderDetail(this.id).subscribe((data) => {
      if (data.dateFrom) {
        data.dateFrom = new Date(data.dateFrom.seconds * 1000);
        data.dateFromTime = this.getHours(moment(data.dateFrom)) + ':' + this.getMinutes(moment(data.dateFrom));

        // console.log(data)
      }
      if (data.dateTo) {
        data.dateTo = new Date(data.dateTo.seconds * 1000);

        data.dateToTime = this.getHours(moment(data.dateTo)) + ':' + this.getMinutes(moment(data.dateTo));
      }

      if (!data.durationDolmetsch) {
        data.durationDolmetsch = '00:00';
      }
      if (!data.durationTravel) {
        data.durationTravel = '00:00';
      }
      if (!data.durationWait) {
        data.durationWait = '00:00';
      }
      if (!data.durationCall) {
        data.durationCall = '00:00';
      }
      this.newItem = data;
      // console.log(this.newItem)
      this.dataSubsubscription3 = this.fs.getDolmetscherList().subscribe((data) => {
        data.map((c) => {
          c.available = false;
        });

        const filtered = data.filter((item) => {
          if (this.newItem.dolmetscherId == item.id) {
            return true;
          }

          return false;
        });

        this.dolmetschers = filtered;
      });
    });

    this.dataSubsubscription4 = this.fs.getRequestListForOrder(this.id).subscribe((data) => {
      this.dataSource.data = data;
    });
  }

  ngOnDestroy() {
    console.log('Destroy');
    this.dataSubsubscription.unsubscribe();
    this.dataSubsubscription2.unsubscribe();
    this.dataSubsubscription3.unsubscribe();
    this.dataSubsubscription4.unsubscribe();
  }

  getHours(moment) {
    return (moment.get('hours') < 10 ? '0' : '') + moment.get('hours');
  }
  getMinutes(moment) {
    return (moment.get('minutes') < 10 ? '0' : '') + moment.get('minutes');
  }
}
