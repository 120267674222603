<header class="navbar navbar-sticky navbar-stuck">
  <div class="site-branding">
    <div class="inner">
      <!-- Off-Canvas Toggle (#shop-categories)-->
      <a class="offcanvas-toggle cats-toggle" data-toggle="offcanvas"><i class="fa fa-bars"></i></a>
      <!-- Off-Canvas Toggle (#mobile-menu)-->
      <a class="offcanvas-toggle menu-toggle" data-toggle="offcanvas"><i class="fa fa-bars"></i></a>
      <!-- Site Logo-->
      <a class="site-logo" routerLink="/home" routerLinkActive="active"
        ><img src="/assets/img/logo-sah-2024.svg" alt="SAH Schaffhausen"
      /></a>
    </div>
  </div>

  <!-- Main Navigation-->
  <nav class="site-menu">
    <ul>
      @if (!ds.userProfile) {
      <li routerLinkActive="active">
        <a routerLink="/login" routerLinkActive="active"><span>Login</span></a>
      </li>
      } @if (ds.userProfile) {
      <li routerLinkActive="active">
        <a routerLink="/home" routerLinkActive="active"><span>Home</span></a>
      </li>
      } @if (ds.userProfile && !isSAH()) {
      <li routerLinkActive="active">
        <a routerLink="/order-create" routerLinkActive="active"><span>Dolmetsch-Auftrag erfassen</span></a>
      </li>
      } @if (ds.userProfile && isSAH() === true) {
      <li routerLinkActive="active">
        <a routerLink="/bildung" routerLinkActive="active"><span>Bildung</span></a>
        <ul class="sub-menu">
          <li><a routerLink="/bildung/persons" routerLinkActive="active">Teilnehmer</a></li>
          <li><a routerLink="/bildung/courses" routerLinkActive="active">Fachbereiche</a></li>
          <li><a routerLink="/bildung/modules" routerLinkActive="active">Lernangebote</a></li>
          <li><a routerLink="/bildung/placementtests" routerLinkActive="active">Einstufungstests</a></li>
          <li><a routerLink="/bildung/waitinglists" routerLinkActive="active">Wartelisten</a></li>
          <li><a routerLink="/bildung/classes" routerLinkActive="active">Klassen</a></li>
        </ul>
      </li>
      } @if (ds.userProfile && isSAH() === true) {
      <li routerLinkActive="active">
        <a routerLink="/interpret" routerLinkActive="active"><span>Dolmetschen</span></a>
        <ul class="sub-menu">
          <li><a routerLink="/interpret/interpreters" routerLinkActive="active">Dolmetscher</a></li>
          <li><a routerLink="/interpret/create-order" routerLinkActive="active">Auftrag erfassen</a></li>
          <li><a routerLink="/interpret/orders" routerLinkActive="active">Aktuelle Aufträge</a></li>
          <li><a routerLink="/interpret/orders-to-export" routerLinkActive="active">Aufträge - Fakturierung</a></li>
          <li><a routerLink="/interpret/orders-archive" routerLinkActive="active">Aufträge - Archiv</a></li>
          <li><a routerLink="/interpret/customers" routerLinkActive="active">Kunden</a></li>
          <li><a routerLink="/interpret/rbs-bern" routerLinkActive="active">Schnittstelle RBS Bern</a></li>
          <li><a routerLink="/interpret/email-log" routerLinkActive="active">eMail Log</a></li>
          <li><a routerLink="/interpret/availability" routerLinkActive="active">Verfügbarkeiten</a></li>
          <li><a routerLink="/interpret/open-requests" routerLinkActive="active">Offene Anfragen</a></li>
        </ul>
      </li>
      } @if (ds.userProfile && isSAH() === true) {
      <li routerLinkActive="active">
        <a routerLink="/auswertung" routerLinkActive="active"><span>Auswertung</span></a>
        <ul class="sub-menu">
          <li><a routerLink="/auswertung/supervisors" routerLinkActive="active">Betreuer</a></li>
          <li><a routerLink="/auswertung/teachers" routerLinkActive="active">Lehrer</a></li>
          <li><a routerLink="/auswertung/childrens" routerLinkActive="active">Kinder im Kibis</a></li>
          <li><a routerLink="/auswertung/competenceprofiles" routerLinkActive="active">Kompetenzprofile</a></li>
          <li><a routerLink="/auswertung/waitinglists" routerLinkActive="active">Wartelisten</a></li>
          <li><a routerLink="/auswertung/classpersons" routerLinkActive="active">Kursteilnehmer Übersicht</a></li>
          <li>
            <a routerLink="/auswertung/personcompetence" routerLinkActive="active">Kompetenzen von Teilnehmern</a>
          </li>
          <li><a routerLink="/auswertung/dropouts" routerLinkActive="active">Dropouts</a></li>
          <li>
            <a routerLink="/auswertung/semesterchange" routerLinkActive="active">Neu-Einteilung Semesterwechsel</a>
          </li>
          <li><a routerLink="/auswertung/presence" routerLinkActive="active">Präsenz</a></li>
          <li><a routerLink="/auswertung/recommendation" routerLinkActive="active">Empfehlung</a></li>
          <!--<li><a routerLink="/auswertung/presencesso" routerLinkActive="active">Präsenz Sozialamt</a></li>-->
        </ul>
      </li>
      } @if (ds.userProfile && isSAH() === true) {
      <li routerLinkActive="active">
        <a routerLink="/stammdaten" routerLinkActive="active"><span>Stammdaten</span></a>
        <ul class="sub-menu">
          <li><a routerLink="/stammdaten/languages" routerLinkActive="active">Sprachen </a></li>
          <li><a routerLink="/stammdaten/nationalities" routerLinkActive="active">Nationalitäten </a></li>
          <li><a routerLink="/stammdaten/materialstatus" routerLinkActive="active">Zivilstand </a></li>
          <li><a routerLink="/stammdaten/residencestatus" routerLinkActive="active">Aufenthaltsstatus </a></li>
          <li><a routerLink="/stammdaten/religions" routerLinkActive="active">Religionen </a></li>
        </ul>
      </li>
      } @if (ds.userProfile && isSAH() === true) {
      <li routerLinkActive="active">
        <a routerLink="/admin" routerLinkActive="active"><span>Admin</span></a>
        <ul class="sub-menu">
          <li><a routerLink="/admin/useraccounts" routerLinkActive="active">Benutzer </a></li>
        </ul>
      </li>
      } @if (ds.userProfile && isSAH() === true) {
      <li routerLinkActive="active">
        <a routerLink="/journal" routerLinkActive="active"><span>Journal</span></a>
      </li>
      } @if (ds.userProfile) {
      <li>
        <button mat-icon-button color="" (click)="logout()"><mat-icon>lock_outline</mat-icon></button>
      </li>
      }
    </ul>
  </nav>

  <!-- Toolbar-->
  <div class="toolbar">
    <div class="inner">
      <div class="tools">
        @if (isLoggedIn()) {
        <div class="account">
          <a routerLink="/myaccount" routerLinkActive="active"></a><i class="fa fa-user"></i>
          <ul class="toolbar-dropdown">
            <li class="sub-menu-user">
              <h4>
                {{ contentData?.data?.viewer?.account?.firstname }} {{ contentData?.data?.viewer?.account?.lastname }}
              </h4>
            </li>
            <li><a routerLink="/myaccount/profile" routerLinkActive="active">Benutzerprofil</a></li>
            <li><a routerLink="/myaccount/orders" routerLinkActive="active">Bestellungen</a></li>
            <li class="sub-menu-separator"></li>
            <li>
              <a routerLink="/logout" routerLinkActive="active"> <i class="fa fa-sign-out"></i>Logout</a>
            </li>
          </ul>
        </div>
        }
      </div>
    </div>
  </div>
</header>

<router-outlet></router-outlet>
