<section class="container-fluid padding-top-5x">
  <h1 class="page-header">Login</h1>

  <div class="row">
    <div class="center-block" style="width: 400px;">
      <form #f="ngForm" class="ui form">
        <div class="form-group">
          <label for="key">Username</label>
          <input
            type="text"
            class="form-control"
            id="username"
            required
            [(ngModel)]="credentials.username"
            name="username"
            placeholder="Username"
          />
        </div>
        <div class="form-group">
          <label for="key">Passwort</label>
          <input
            type="password"
            class="form-control"
            id="password"
            required
            [(ngModel)]="credentials.password"
            name="password"
            placeholder="Passwort"
          />
        </div>
        <button type="submit" class="btn btn-success" (click)="login()">Login</button>

        <button class="btn btn-default" routerLink="/reset" routerLinkActive="active">Passwort zurücksetzen</button>
      </form>
    </div>
  </div>
</section>
