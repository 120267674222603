import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { DataService } from '../firebase.service';
import { MessageService } from '../message.service';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { FormsModule } from '@angular/forms';


@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css'],
    standalone: true,
    imports: [FormsModule, RouterLink, RouterLinkActive]
})
export class LoginComponent implements OnInit {
  credentials: any;
  resetemail: any;

  constructor(private ds: DataService, private ms: MessageService, private as: AuthService) {
    const that = this;
    this.credentials = { username: '', password: '' };
    this.resetemail = '';
  }

  ngOnInit() { }

  login() {
    const that = this;
    this.as
      .login(this.credentials.username, this.credentials.password)
      .then(function (authData) {
        // console.log("Authenticated successfully with payload-", authData);
        if (authData.user.displayName) {
          that.ms.alertSuccess('Eingeloggt', 'Hi ' + authData.user.displayName);
        } else {
          that.ms.alertSuccess('Eingeloggt', 'Hi!');
        }
      })
      .catch(function (err) {
        that.ms.alertError('Fehler: ' + (err as any).code, err.message);
      });
  }
}
