import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'objectid',
    standalone: true
})
export class ObjectidPipe implements PipeTransform {
  transform(value: any, object?: any): any {
    // let exp = parseFloat(exponent);

    if (!value) {
      return '';
    }
    // if (!value.val()) { return ''; }
    // console.log(value.val());

    switch (object) {
      case 'competenceprofile':
        if (!value.val()) {
          return '';
        }
        return value.val().dateofevaluation;
      case 'person':
        if (!value.val()) {
          return '';
        }
        return value.val().firstname + ' ' + value.val().lastname;
      case 'class':
        if (!value.val()) {
          return '';
        }
        return value.val().name;
      case 'waitinglist':
        if (!value.val()) {
          return '';
        }
        return value.val().name;
      case 'classperson':
        if (!value.val()) {
          return '';
        }
        return value.val().firstname + ' ' + value.val().lastname;
      case 'personcompetence':
        if (!value.val()) {
          return '';
        }
        return value.val().firstname + ' ' + value.val().lastname;
      case 'personpresence':
        return value;
      case 'classsection':
        return value;
      case 'useraccount':
        if (!value.val()) {
          return '';
        }
        return value.val().firstname + ' ' + value.val().lastname;
      case 'module':
        if (!value.val()) {
          return '';
        }
        return value.val().name;
      case 'course':
        if (!value.val()) {
          return '';
        }
        return value.val().name;
      default:
        return null;
    }
  }
}
