import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import moment from 'moment';
import { AuthService } from '../../auth.service';
import { FirestoreService } from '../../firestore.service';
import { AsyncPipe } from '@angular/common';
import { MatButton } from '@angular/material/button';
import { MatDatepickerInput, MatDatepickerToggle, MatDatepicker } from '@angular/material/datepicker';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatInput } from '@angular/material/input';
import { MatOption } from '@angular/material/core';
import { FormsModule } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { MatFormField, MatSuffix } from '@angular/material/form-field';

@Component({
    selector: 'supervisor-app-order-create',
    templateUrl: './order-create.component.html',
    styleUrls: ['./order-create.component.css'],
    standalone: true,
    imports: [MatFormField, MatSelect, FormsModule, MatOption, MatInput, MatCheckbox, MatDatepickerInput, MatDatepickerToggle, MatSuffix, MatDatepicker, MatButton, AsyncPipe]
})
export class SupervisorOrderCreateComponent implements OnInit {
  newItem: any = this.initNewItem();
  dolmetschTypes: any;
  dolmetschCategories: any;
  languages: any;
  regions: any;
  dolmetschers: any;
  customers: any = [];
  isSaving: any = false;

  constructor(private fs: FirestoreService, private router: Router, private as: AuthService) {}

  initNewItem() {
    return (this.newItem = {
      changedAt: new Date(),
      city: '',
      contactFirstname: '',
      contactLastname: '',
      contactTelefon: '',
      contactEmail: '',
      createdAt: new Date(),
      customer: null,
      customerId: null,
      dateFrom: new Date(),
      dateFromTime: '08:00',
      dateTo: new Date(),
      dateToTime: '09:00',
      dolmetschCategory: null,
      dolmetschCategoryId: null,
      dolmetschType: null,
      dolmetschTypeId: null,
      numberOfPages: null,
      difficulty: null,
      dolmetscher: null,
      dolmetscherId: null,
      duration: null,
      durationDolmetsch: '01:00',
      durationTravel: '00:00',
      durationWait: '00:00',
      durationCall: '00:00',
      extraSheet: false,
      expenseTravel: 0,
      keyExpenses: null,
      language: null,
      languageId: null,
      postalCode: null,
      region: null,
      regionId: null,
      statusAssigned: false,
      statusCleared: false,
      statusConfirmed: false,
      statusExported: false,
      street: '',
      noshow: false,
      active: true,
      nameendcustomer: '',
      dmReminderInputHoursSent: false,
      revision: 0
    });
  }
  ngOnInit() {
    this.dolmetschTypes = this.fs.getDolmetschTypeList();
    this.dolmetschCategories = this.fs.getDolmetschCategoryList();
    this.languages = this.fs.getLanguageList();
    this.getDolmetscher(null);

    this.regions = this.fs.getRegionList();

    this.fs.getCustomerListOfSupervisor(this.as.getCurrentUser().email).subscribe((data) => {
      this.customers = data;
    });
  }
  getDolmetscher(region) {
    this.newItem.dolmetscher = '';
    this.dolmetschers = this.fs.getDolmetscherListForRegion(region);
  }
  isWrittenTranslation() {
    if (this.newItem.dolmetschTypeId == '9Q88e2frktuGSXxuPbi9') {
      return true;
    }
  }
  isTrialog() {
    if (this.newItem.dolmetschTypeId == 'KLKoilDYfFKpmZV35H7q') {
      return true;
    }
  }
  save() {
    this.isSaving = true;
    switch (this.newItem.postalCode) {
      case '8200':
      case '8201':
      case '8202':
      case '8203':
      case '8204':
      case '8207':
      case '8205':
      case '8208':
        this.newItem.durationTravel = '00:15';
        this.newItem.expenseTravel = 0;
        break;

      case '8222':
      case '8212':
      case '8232':
      case '8234':
      case '8240':
      case '8245':
        this.newItem.durationTravel = '00:15';
        this.newItem.expenseTravel = 5;
        break;

      case '8243':
      case '8233':
      case '8241':
      case '8228':
      case '8242':
      case '8263':
      case '8454':
      case '8239':
      case '8223':
      case '8214':
      case '8215':
      case '8261':
      case '8231':
      case '8235':
      case '8224':
      case '8213':
      case '8216':
      case '8236':
      case '8218':
      case '8262':
      case '8455':
      case '8226':
      case '8225':
      case '8260':
      case '8219':
      case '8217':
        this.newItem.durationTravel = '00:30';
        if (
          this.newItem.dolmetschTypeId == 'KLKoilDYfFKpmZV35H7q' ||
          this.newItem.dolmetschTypeId == 'c2NRf86oZZ9hWhEIWd75'
        ) {
          this.newItem.expenseTravel = 10;
        }
        break;

      case '8400':
        this.newItem.durationTravel = '00:30';
        if (
          this.newItem.dolmetschTypeId == 'KLKoilDYfFKpmZV35H7q' ||
          this.newItem.dolmetschTypeId == 'c2NRf86oZZ9hWhEIWd75'
        ) {
          this.newItem.expenseTravel = 15;
        }
        break;

      case '8180':
      case '8424':
        this.newItem.durationTravel = '00:45';
        if (
          this.newItem.dolmetschTypeId == 'KLKoilDYfFKpmZV35H7q' ||
          this.newItem.dolmetschTypeId == 'c2NRf86oZZ9hWhEIWd75'
        ) {
          this.newItem.expenseTravel = 20;
        }
        break;
      case '8000':
        this.newItem.durationTravel = '00:45';
        if (
          this.newItem.dolmetschTypeId == 'KLKoilDYfFKpmZV35H7q' ||
          this.newItem.dolmetschTypeId == 'c2NRf86oZZ9hWhEIWd75'
        ) {
          this.newItem.expenseTravel = 30;
        }
        break;

      default:
        this.newItem.durationTravel = '00:00';
        this.newItem.expenseTravel = 0;
        break;
    }

    let dateFrom = moment(this.newItem.dateFrom).set('hours', this.newItem.dateFromTime.substr(0, 2));
    dateFrom = dateFrom.set('minutes', this.newItem.dateFromTime.substr(3, 2));
    dateFrom = dateFrom.set('seconds', 0);

    this.newItem.dateFrom = dateFrom.toDate();

    let dateTo = moment(this.newItem.dateFrom).set('hours', this.newItem.dateToTime.substr(0, 2));
    dateTo = dateTo.set('minutes', this.newItem.dateToTime.substr(3, 2));
    dateTo = dateTo.set('seconds', 0);

    this.newItem.dateTo = dateTo.toDate();

    const df = moment(this.newItem.dateFrom);
    const dt = moment(this.newItem.dateTo);
    const duration = moment.duration(dt.diff(df));
    const durationFormatted = moment.utc(duration.asMilliseconds()).format('HH:mm');
    this.newItem.durationDolmetsch = durationFormatted;

    this.fs
      .createOrder(this.newItem)
      .then((data) => {
        this.router.navigate(['/home']);
        this.newItem = this.initNewItem();
        this.isSaving = false;
      })
      .catch((err) => {
        this.isSaving = false;
      });
  }
  selectCustomer() {
    const index = this.customers.map((e) => e.id).indexOf(this.newItem.customerId);

    this.newItem.street = this.customers[index].street;
    this.newItem.postalCode = this.customers[index].postalCode;
    this.newItem.city = this.customers[index].city;
  }

  canBeSaved() {
    if (this.newItem.customerId == null) {
      return false;
    }
    if (this.newItem.dolmetschCategoryId == null) {
      return false;
    }
    if (this.newItem.languageId == null) {
      return false;
    }
    if (this.newItem.dolmetschTypeId == null) {
      return false;
    }
    if (this.isSaving == true) {
      return false;
    }

    return true;
  }
}
