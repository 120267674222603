import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'action',
    standalone: true
})
export class ActionPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    switch (value) {
      case 'C':
        return 'Erstellen';
      case 'R':
        return 'Lesen';
      case 'U':
        return 'Ändern';
      case 'D':
        return 'Löschen';
      default:
        return null;
    }
  }
}
