import { Component, OnInit } from '@angular/core';

import { AuthService } from '../auth.service';
import { MessageService } from '../message.service';
import { Router } from '@angular/router';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.css'],
    standalone: true,
    imports: [FormsModule]
})
export class ResetPasswordComponent implements OnInit {
  resetemail: any;

  constructor(private router: Router, private ms: MessageService, private as: AuthService) {}

  ngOnInit() {}

  reset() {
    const that = this;
    this.as.reset(this.resetemail).then(
      function() {
        that.ms.alertSuccess(
          'eMail versendet',
          'Bitte schau in deinem Posteingang nach und klicke auf den Aktivierungslink. '
        );
        that.router.navigate(['/login']);
      },
      function(error) {
        that.ms.alertError('Das hat leider nicht funktioniert', 'Bitte versuchs später nochmals.');
      }
    );
  }
}
