<section class="container-fluid padding-top-5x">
  <h1 class="page-header">Journal</h1>

  <div class="row">
    <div class="col-sm-12">
      <button type="submit" class="btn btn-default pull-right" (click)="export2Excel()">
        <span class="fa fa-export" aria-hidden="true"></span> Export
      </button>
    </div>
  </div>

  <div class="example-container mat-elevation-z8">
    <div class="example-header">
      <mat-form-field>
        <mat-select placeholder="Object" [(ngModel)]="filter.object">
          <mat-option></mat-option>
          <mat-option value="competenceprofile">Kompetenzprofil</mat-option>
          <mat-option value="person">Person</mat-option>
          <mat-option value="child">Kind</mat-option>
          <mat-option value="class">Klasse</mat-option>
          <mat-option value="waitinglist">Warteliste</mat-option>
          <mat-option value="classperson">Teilnehmer in Klasse</mat-option>
          <mat-option value="personcompetence">Kompetenzen einer Person</mat-option>
          <mat-option value="personpresence">Präsenz einer Person</mat-option>
          <mat-option value="classsection">Unterrichtseinheit</mat-option>
          <mat-option value="useraccount">Benutzer</mat-option>
          <mat-option value="userfunction">Funktion eines Benutzers</mat-option>
          <mat-option value="authrolemember">Berechtungsrollen Zuordnung</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-select placeholder="Object Id" [(ngModel)]="filter.objectid">
          <mat-option></mat-option>
          @for (element of objectidList; track element) {
            <mat-option [value]="element.objectid">{{
              element.objectid | conversionExit: element.object | async | objectid: element.object
            }}</mat-option>
          }
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-select placeholder="Ref. Object" [(ngModel)]="filter.relatedobject">
          <mat-option></mat-option>
          <mat-option value="competenceprofile">Kompetenzprofil</mat-option>
          <mat-option value="person">Person</mat-option>
          <mat-option value="child">Kind</mat-option>
          <mat-option value="class">Klasse</mat-option>
          <mat-option value="waitinglist">Warteliste</mat-option>
          <mat-option value="classperson">Teilnehmer in Klasse</mat-option>
          <mat-option value="personcompetence">Kompetenzen einer Person</mat-option>
          <mat-option value="personpresence">Präsenz einer Person</mat-option>
          <mat-option value="classsection">Unterrichtseinheit</mat-option>
          <mat-option value="useraccount">Benutzer</mat-option>
          <mat-option value="userfunction">Funktion eines Benutzers</mat-option>
          <mat-option value="authrolemember">Berechtungsrollen Zuordnung</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-select placeholder="Ref. Object Id" [(ngModel)]="filter.relatedobjectid">
          <mat-option></mat-option>
          @for (element of relatedobjectidList; track element) {
            <mat-option [value]="element.relatedobjectid"
              >{{
              element.relatedobjectid | conversionExit: element.relatedobject | async | objectid: element.relatedobject
              }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
      <button mat-icon-button color="primary" (click)="doSearch()"><mat-icon>loop</mat-icon></button>
    </div>

    <mat-table #table [dataSource]="dataSource" matSort>
      <!-- Position Column -->
      <ng-container matColumnDef="userid">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Benutzer </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.userid | conversionExit: 'user' | async | resolveFullname }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="object">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Objekt </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.object | object }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="objectid">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Objekt Id </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.objectid | conversionExit: element.object | async | objectid: element.object }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="action">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Aktion </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.action | action }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="timestamp">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Zeit </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.timestamp | date: 'dd.MM.yyyy HH:mm:ss' }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="description">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Beschreibung </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.description }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="relatedobject">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Ref. Objekt </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.relatedobject | object }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="relatedobjectid">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Ref. Objekt Id </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{
          element.relatedobjectid | conversionExit: element.relatedobject | async | objectid: element.relatedobject
          }}
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
    <mat-paginator
      [length]="length"
      [pageSize]="pageSize"
      [pageSizeOptions]="pageSizeOptions"
      (page)="pageEvent = $event"
      >
    </mat-paginator>
  </div>
</section>
