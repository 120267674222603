import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import { MatTableDataSource, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow } from '@angular/material/table';
import moment from 'moment';
import * as _ from 'underscore';
import { ExcelService } from '../../excel.service';
import { DataService } from '../../firebase.service';
import { MessageService } from '../../message.service';
import firebase from 'firebase/compat/app';
import { ResolveNamePipe } from '../../mpipes/resolve-name.pipe';
import { ResolveFullnamePipe } from '../../mpipes/resolve-fullname.pipe';
import { ConversionExitPipe } from '../../mpipes/conversion-exit.pipe';
import { NgClass, AsyncPipe } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { MatDatepickerInput, MatDatepickerToggle, MatDatepicker } from '@angular/material/datepicker';
import { MatInput } from '@angular/material/input';
import { MatOption } from '@angular/material/core';
import { FormsModule } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { MatFormField, MatSuffix } from '@angular/material/form-field';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

@Component({
    selector: 'app-supervisor-presence',
    templateUrl: './analytics-presence.component.html',
    styleUrls: ['./analytics-presence.component.css'],
    standalone: true,
    imports: [MatFormField, MatSelect, FormsModule, MatOption, MatInput, MatDatepickerInput, MatDatepickerToggle, MatSuffix, MatDatepicker, MatIconButton, MatIcon, MatTable, MatSort, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatSortHeader, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, NgClass, MatPaginator, AsyncPipe, ConversionExitPipe, ResolveFullnamePipe, ResolveNamePipe]
})
export class AnalyticsPresenceComponent implements OnInit {
  @ViewChild(MatSort, { static: false }) materialSort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  displayedColumns = [
    // 'supervisor',
    'lastname',
    'firstname',
    'mobile',
    'class',
    'unexcused',
    'attendance'
  ];
  class: any;
  classList: any;
  searchResultList: any = [];
  classSectionList: any = [];

  filter: any;
  sort: any;

  showSpinner = true;

  supervisorList: any = [];
  dataSource = new MatTableDataSource(this.searchResultList);

  // MatPaginator Inputs
  length: any;
  pageSize: any = 10;
  pageSizeOptions = [5, 10, 25, 100];

  // MatPaginator Output
  pageEvent: PageEvent;

  constructor(private es: ExcelService, public ds: DataService, private ms: MessageService) {
    const that = this;
    this.searchResultList = [];
    this.classList = [];

    this.filter = {
      firstname: '',
      lastname: '',
      language: '',
      supervisor: '',
      class: '',
      datefrom: moment().startOf('month'),
      dateto: moment().endOf('month'),
      active: 'A'
    };
    this.sort = { value: '', direction: '', sorter: [] };

    this.supervisorList = [];

    this.ds
      .getClassList()
      .orderByChild('yearsemester')
      .equalTo(this.ds.selectedYearSemester)
      .once(
        'value',
        (snapshot) => {
          snapshot.forEach((snap) => {
            const object = snap.val();
            object.id = snap.key;
            that.classList.push(object);
          });
        },
        function(err) {
          that.ms.alertError('Fehler (-1): ' + (err as any).code, err.message);
        }
      );
  }
  ngOnInit() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        console.log('uid: ', user.uid);

        this.filter.supervisor = user.uid;
        this.doSearch();
      }
    });
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.materialSort;
    this.dataSource.paginator = this.paginator;
  }

  doSearch() {
    const that = this;
    this.searchResultList = [];
    this.classSectionList = [];
    that.dataSource.data = [];

    this.ds
      .getClassList()
      .orderByChild('yearsemester')
      .equalTo(this.ds.selectedYearSemester)
      .once(
        'value',
        (snapshot) => {
          snapshot.forEach((snapshot0) => {
            // var classDetails = snapshot0.val();
            // classDetails.id = snapshot0.key;

            that.ds
              .getClassList()
              .child(snapshot0.key)
              .once(
                'value',
                (snapshot) => {
                  const object = snapshot.val();
                  object.id = snapshot.key;
                  that.class = object;
                },
                function(err) {
                  that.ms.alertError('Fehler (-2): ' + (err as any).code, err.message);
                }
              );

            that.ds
              .getClassPersonList()
              .child(snapshot0.key)
              .on(
                'child_added',
                (snapshot) => {
                  const classperson = snapshot.val();

                  that.ds
                    .getPersonList()
                    .child(snapshot.key)
                    .once(
                      'value',
                      function(snapshot2) {
                        const object = snapshot2.val();

                        if (object.supervisor == that.filter.supervisor && object.supervisor !== '') {
                          object.id = snapshot2.key;
                          object.sections = {};
                          object.foreign = {
                            calculation: { unexcused: 0, unexcusedDate: [], attendance: 0, absent: 0 }
                          };
                          object.class = this.class; // from data context
                          object.classPerson = object.class + '-' + object.id;
                          object.foreign.classPersonActive = this.active; // from data context
                          object.foreign.classPersonDroppedout = this.droppedout; // from data context

                          if (
                            (object.class == that.filter.class && object.class !== '') ||
                            that.filter.class == '' ||
                            !that.filter.class
                          ) {
                            if (
                              (object.foreign.classPersonActive == true && that.filter.active == 'A') ||
                              (object.foreign.classPersonActive == false && that.filter.active == 'I')
                            ) {
                              that.searchResultList.push(object);

                              // Sections pro Person, filter nach Klasse
                              that.ds
                                .getPersonPresenceList(snapshot.key)
                                .orderByChild('class')
                                .equalTo(snapshot0.key)
                                .once(
                                  'value',
                                  async (snapshot3) => {
                                    await snapshot3.forEach((snapshot4) => {
                                      const object = snapshot4.val();
                                      object.id = snapshot4.key;
                                      object.classPerson = object.class + '-' + object.person;
                                      switch (object.presence) {
                                        case '2':
                                          object.presenceText = 'ok';
                                          break;
                                        case '3':
                                          object.presenceText = 'Z';
                                          break;

                                        case '1':
                                          object.presenceText = 'A';
                                          break;

                                        case '4':
                                          object.presenceText = 'X';
                                          break;

                                        default:
                                          object.presenceText = object.presence;
                                          break;
                                      }
                                      if (object.presence == '4') {
                                        // nur eine Unentschuldigte Absenz wenn mehrere pro object.date
                                        if (
                                          that.searchResultList[
                                            that.searchResultList.map((x) => x.classPerson).indexOf(object.classPerson)
                                          ].foreign.calculation.unexcusedDate
                                            .map((x) => x)
                                            .indexOf(object.date) == -1
                                        ) {
                                          that.searchResultList[
                                            that.searchResultList.map((x) => x.classPerson).indexOf(object.classPerson)
                                          ].foreign.calculation.unexcused += 1;
                                          that.searchResultList[
                                            that.searchResultList.map((x) => x.classPerson).indexOf(object.classPerson)
                                          ].foreign.calculation.unexcusedDate.push(object.date);
                                        }
                                      }
                                      if (object.presence == '4' || object.presence == '1') {
                                        that.searchResultList[
                                          that.searchResultList.map((x) => x.classPerson).indexOf(object.classPerson)
                                        ].foreign.calculation.absent += 1;
                                      }
                                      that.searchResultList[
                                        that.searchResultList.map((x) => x.classPerson).indexOf(object.classPerson)
                                      ].sections[object.key] = object;

                                      if (
                                        Object.keys(
                                          that.searchResultList[
                                            that.searchResultList.map((x) => x.classPerson).indexOf(object.classPerson)
                                          ].sections
                                        ).length > 0
                                      ) {
                                        that.searchResultList[
                                          that.searchResultList.map((x) => x.classPerson).indexOf(object.classPerson)
                                        ].foreign.calculation.attendance =
                                          (100 /
                                            Object.keys(
                                              that.searchResultList[
                                                that.searchResultList
                                                  .map((x) => x.classPerson)
                                                  .indexOf(object.classPerson)
                                              ].sections
                                            ).length) *
                                          (Object.keys(
                                            that.searchResultList[
                                              that.searchResultList
                                                .map((x) => x.classPerson)
                                                .indexOf(object.classPerson)
                                            ].sections
                                          ).length -
                                            that.searchResultList[
                                              that.searchResultList
                                                .map((x) => x.classPerson)
                                                .indexOf(object.classPerson)
                                            ].foreign.calculation.absent);

                                        that.searchResultList[
                                          that.searchResultList.map((x) => x.classPerson).indexOf(object.classPerson)
                                        ].foreign.calculation.attendance =
                                          Math.round(
                                            that.searchResultList[
                                              that.searchResultList
                                                .map((x) => x.classPerson)
                                                .indexOf(object.classPerson)
                                            ].foreign.calculation.attendance * 10
                                          ) / 10;
                                      } else {
                                        that.searchResultList[
                                          that.searchResultList.map((x) => x.classPerson).indexOf(object.classPerson)
                                        ].foreign.calculation.attendance = 100;
                                      }
                                    });
                                    that.dataSource.data = that.searchResultList;

                                    // that.showSpinner = false
                                  },
                                  function(err) {
                                    that.ms.alertError('Fehler (-3): ' + (err as any).code, err.message);
                                  }
                                ); // context dDATA);
                            } // if Aktive Status
                          } // if class
                        } // if supervisor
                      },
                      function(err) {
                        // that.ms.alertError('Fehler: ' + (err as any).code, err.message);
                      },
                      classperson
                    );
                },
                function(err) {
                  that.ms.alertError('Fehler (-4): ' + (err as any).code, err.message);
                }
              );

            // Schultage
            that.ds.getClassSectionList(snapshot0.key).once(
              'value',
              (snapshot) => {
                snapshot.forEach((snapshot0) => {
                  const object = snapshot0.val();
                  object.id = snapshot0.key;
                  // var index = that.classSectionList.indexOf(x => x.id == object.id)
                  // here you can check specific property for an object whether it exist in your array or not
                  if (that.classSectionList.map((x) => x.id).indexOf(object.id) === -1) {
                    if (
                      moment(object.date).isSameOrAfter(that.filter.datefrom) &&
                      moment(object.date).isSameOrBefore(that.filter.dateto)
                    ) {
                      that.classSectionList.push(object);
                      that.classSectionList = _.chain(that.classSectionList)
                        .sortBy('key')
                        .value();
                    }
                  }
                });
              },
              function(err) {
                that.ms.alertError('Fehler (-5): ' + (err as any).code, err.message);
              }
            );
          });
        },
        function(err) {
          that.ms.alertError('Fehler (-6): ' + (err as any).code, err.message);
        }
      );
    // this.showSpinner = false
  }

  export2Excel() {
    const cols = this.classSectionList.map((cls) => {
      return { name: cls.key, object: 'section' };
    });
    this.es.exportAsExcelFile(this.dataSource.data, 'analytics-presence', cols);
  }
  changeSorting(value) {
    if (this.sort.value == value) {
      if (this.sort.direction == 'asc') {
        this.sort.direction = 'desc';
        this.sort.sorter = ['-' + value];
      } else {
        this.sort.value = '';
        this.sort.direction = '';
        this.sort.sorter = [];
      }
    } else {
      this.sort.value = value;
      this.sort.direction = 'asc';
      this.sort.sorter = [value];
    }
  }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }
}
