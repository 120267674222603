<section class="container-fluid padding-top-1x">
  <h3 class="page-header">
    Dolmetsch Aufträge ({{ dataSource.data.length }}) für {{ customer.name }}
    <!-- <button type="submit" class="btn btn-success btn-circle btn-lg" routerLink="/interpret/create-order" routerLinkActive="active" title="neue Klasse anlegen">
    <i class="fa fa-plus"></i>
  </button> -->
</h3>

<!-- <div class="row">
<div class="col-sm-6">

</div>
<div class="col-sm-6">
  <button type="submit" class="btn btn-default pull-right" (click)="export2Excel()"><span class="fa fa-export" aria-hidden="true"></span> Export</button>
</div>
</div> -->

<div class="example-container mat-elevation-z8">
  <div class="example-header">
    <!-- <mat-form-field>
    <input matInput (keyup)="applyFilter()" [(ngModel)]="fs.filter.orders" placeholder="Filter">
  </mat-form-field> -->

  <!-- <mat-form-field>
  <mat-select placeholder="Kunde" (selectionChange)="changeFilter()" [(ngModel)]="fs.filter.customer">

    <mat-option value="">Alle</mat-option>
    <mat-option  *ngFor="let item of customers | async" [value]="item.id">{{item.name}}</mat-option>
  </mat-select>
</mat-form-field> -->
<!-- <mat-form-field>
<mat-select placeholder="Kategorie" (selectionChange)="changeFilter()" [(ngModel)]="fs.filter.dolmetschCategory">

  <mat-option value="">Alle</mat-option>
  <mat-option  *ngFor="let item of dolmetschCategories | async" [value]="item.id">{{item.title}}</mat-option>
</mat-select>
</mat-form-field> -->

<mat-form-field>
  <mat-select placeholder="Sprache" (selectionChange)="changeFilter()" [(ngModel)]="fs.filter.language">
    <mat-option value="">Alle</mat-option>
    @for (item of languages | async; track item) {
      <mat-option [value]="item.id">{{ item.language }}</mat-option>
    }
  </mat-select>
</mat-form-field>

<!-- <mat-form-field>
<mat-select placeholder="Typ" (selectionChange)="changeFilter()" [(ngModel)]="fs.filter.dolmetschType">
  <mat-option value="">Alle</mat-option>
  <mat-option *ngFor="let item of dolmetschType | async" [value]="item.id">{{ item.title }}</mat-option>
</mat-select>
</mat-form-field> -->

<mat-form-field>
  <input matInput (keyup)="changeFilter()" [(ngModel)]="fs.filter.dolmetscher" placeholder="Dolmetscher" />
</mat-form-field>

<mat-form-field>
  <input matInput [matDatepicker]="myDatepicker" [(ngModel)]="fs.filter.date" placeholder="Datum"
    (dateChange)="changeFilter()" />
    <mat-datepicker-toggle matSuffix [for]="myDatepicker"></mat-datepicker-toggle>
    <mat-datepicker #myDatepicker></mat-datepicker>
  </mat-form-field>
</div>

<mat-table #table [dataSource]="dataSource" matSort>
  <ng-container matColumnDef="customer">
    <mat-header-cell *matHeaderCellDef mat-sort-header> Kunde </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{ element._customer?.name }} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="date">
    <mat-header-cell *matHeaderCellDef mat-sort-header> Datum </mat-header-cell>
    <mat-cell *matCellDef="let element">
      {{ element?.dateFrom?.seconds * 1000 | date: 'dd.MM.yyyy' }} <br />
      {{ element?.dateFrom?.seconds * 1000 | date: 'HH:mm' }} -
      {{ element?.dateTo?.seconds * 1000 | date: 'HH:mm' }}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="durationDolmetsch">
    <mat-header-cell *matHeaderCellDef mat-sort-header> Dauer / Seiten</mat-header-cell>
    <mat-cell *matCellDef="let element">
      @if (!isWrittenTranslation(element)) {
        {{ element.durationDolmetsch }}
      }
      @if (isWrittenTranslation(element)) {
        {{ element.numberOfPages }}
      }
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="durationTravel">
    <mat-header-cell *matHeaderCellDef mat-sort-header> Reisezeit </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{ element.durationTravel }} </mat-cell>
  </ng-container>
  <ng-container matColumnDef="durationWait">
    <mat-header-cell *matHeaderCellDef mat-sort-header> Wartezeit </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{ element.durationWait }} </mat-cell>
  </ng-container>
  <ng-container matColumnDef="durationCall">
    <mat-header-cell *matHeaderCellDef mat-sort-header> Telefonzeit </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{ element.durationCall }} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="dolmetschCategory">
    <mat-header-cell *matHeaderCellDef [ngClass]="''"> Kategorie </mat-header-cell>
    <mat-cell *matCellDef="let element" [ngClass]="''">
      {{ element._dolmetschCategory?.title }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="dolmetschType">
    <mat-header-cell *matHeaderCellDef [ngClass]="''"> Typ </mat-header-cell>
    <mat-cell *matCellDef="let element" [ngClass]="''">
      {{ element._dolmetschType?.title }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="rbsbernpk">
    <mat-header-cell *matHeaderCellDef [ngClass]="''"> RBS PK </mat-header-cell>
    <mat-cell *matCellDef="let element" [ngClass]="''">
      {{ element.rbsbernpk }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="region">
    <mat-header-cell *matHeaderCellDef [ngClass]="''"> Region </mat-header-cell>
    <mat-cell *matCellDef="let element" [ngClass]="''">
      {{ element._region?.title }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="city">
    <mat-header-cell *matHeaderCellDef [ngClass]="''"> Ort </mat-header-cell>
    <mat-cell *matCellDef="let element" [ngClass]="''">
      {{ element.city }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="language">
    <mat-header-cell *matHeaderCellDef [ngClass]="''"> Sprache </mat-header-cell>
    <mat-cell *matCellDef="let element" [ngClass]="''">
      {{ element._language?.language }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="dolmetscher">
    <mat-header-cell *matHeaderCellDef [ngClass]="''"> Dolmetscher </mat-header-cell>
    <mat-cell *matCellDef="let element" [ngClass]="''">
      {{ element._dolmetscher?.firstname }} {{ element._dolmetscher?.lastname }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="status">
    <mat-header-cell *matHeaderCellDef [ngClass]="'statuscol'"> Status </mat-header-cell>
    <mat-cell *matCellDef="let element" [ngClass]="'statuscol'">
      @if (element.openRequests > 0 || element.statusAssigned == true) {
        <mat-icon color="status-done">send
        </mat-icon>
      }
      @if (element.openRequests == 0 && element.statusAssigned == false) {
        <mat-icon color="status-danger">send
        </mat-icon>
      }
      @if (element.statusAssigned == true) {
        <mat-icon color="status-done">check_circle</mat-icon>
      }
      @if (element.statusAssigned != true) {
        <mat-icon color="status-open">check_circle</mat-icon>
      }
      @if (element.statusCleared == true) {
        <mat-icon color="status-done">flag</mat-icon>
      }
      @if (element.statusCleared != true) {
        <mat-icon color="status-open">flag</mat-icon>
      }
    </mat-cell>
  </ng-container>

  <!-- <ng-container matColumnDef="change">
  <mat-header-cell *matHeaderCellDef [ngClass]="'actioncol'"> Ändern </mat-header-cell>
  <mat-cell *matCellDef="let element" [ngClass]="'actioncol'"><button mat-icon-button color="primary"
    [disabled]="element.statusConfirmed == true" routerLink="/interpret/order/{{ element.id }}"
    routerLinkActive="active">
    <mat-icon>create</mat-icon>
  </button>
</mat-cell>
</ng-container> -->

<!-- <ng-container matColumnDef="delete">
<mat-header-cell *matHeaderCellDef [ngClass]="'actioncol'"> Löschen </mat-header-cell>
<mat-cell *matCellDef="let element" [ngClass]="'actioncol'">
  <button *ngIf="element.active" [disabled]="element.statusConfirmed == true" mat-icon-button color="warn"
    (click)="openDelete(element)">
    <mat-icon>delete</mat-icon>
  </button>

  <button *ngIf="!element.active" [disabled]="element.statusConfirmed == true" mat-icon-button color="warn"
    (click)="undelete(element)">
    <mat-icon>delete_forever</mat-icon>
  </button>
</mat-cell>
</ng-container> -->

<ng-container matColumnDef="gotoOrderDetail">
  <mat-header-cell *matHeaderCellDef [ngClass]="'actioncol'"> Anzeigen </mat-header-cell>
  <mat-cell *matCellDef="let element" [ngClass]="'actioncol'">
    <button mat-icon-button color="primary" (click)="gotoOrderDetail(element)">
      <mat-icon>arrow_forward</mat-icon>
    </button>
  </mat-cell>
</ng-container>

<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns" [ngClass]="{
          'highlight-finished': row.statusCleared == true,
          'highlight-norequests': row.openRequests == 0 && row.statusAssigned == false,
          'highlight-notassiged':
            row.openRequests > 0 &&
            row.statusAssigned == false &&
            today.isSameOrAfter(toMoment(row.date), 'day') == true
        }"></mat-row>
</mat-table>
<mat-paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
  (page)="pageEvent = $event">
</mat-paginator>
</div>
<br />
<br />
</section>