import { Location, AsyncPipe, DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import { MatTableDataSource, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'underscore';
import { ExcelService } from '../excel.service';
import { DataService } from '../firebase.service';
import { MessageService } from '../message.service';
import { ActionPipe } from '../mpipes/action.pipe';
import { ObjectidPipe } from '../mpipes/objectid.pipe';
import { ObjectPipe } from '../mpipes/object.pipe';
import { ResolveFullnamePipe } from '../mpipes/resolve-fullname.pipe';
import { ConversionExitPipe } from '../mpipes/conversion-exit.pipe';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { MatOption } from '@angular/material/core';
import { FormsModule } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { MatFormField } from '@angular/material/form-field';

@Component({
    selector: 'app-journal',
    templateUrl: './journal.component.html',
    styleUrls: ['./journal.component.css'],
    standalone: true,
    imports: [MatFormField, MatSelect, FormsModule, MatOption, MatIconButton, MatIcon, MatTable, MatSort, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatSortHeader, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatPaginator, AsyncPipe, DatePipe, ConversionExitPipe, ResolveFullnamePipe, ObjectPipe, ObjectidPipe, ActionPipe]
})
export class JournalComponent implements OnInit {
  @ViewChild(MatSort, { static: false }) materialSort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  searchResultList: any = [];
  displayedColumns = [
    'userid',
    'object',
    'objectid',
    'action',
    'timestamp',
    'description',
    'relatedobject',
    'relatedobjectid'
  ];

  objectidList: any = [];
  relatedobjectidList: any = [];
  filter: any;

  // MatPaginator Output
  pageEvent: PageEvent;

  dataSource = new MatTableDataSource(this.searchResultList);

  // MatPaginator Inputs
  length: any;
  pageSize: any = 10;
  pageSizeOptions = [5, 10, 25, 100];

  constructor(
    private es: ExcelService,
    public ds: DataService,
    private ms: MessageService,
    private route: ActivatedRoute,
    private router: Router,
    private _location: Location
  ) {
    this.filter = {
      firstname: '',
      lastname: '',
      language: '',
      relatedobject: '',
      relatedobjectid: ''
    };
  }

  ngOnInit() {
    this.doSearch();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.materialSort;
    this.dataSource.paginator = this.paginator;
  }

  doSearch() {
    const that = this;
    this.searchResultList = [];
    this.objectidList = [];
    this.relatedobjectidList = [];

    this.ds
      .getJournalList()
      .orderByKey()
      .limitToLast(20)
      .once(
        'value',
        (snapshot) => {
          snapshot.forEach((snap) => {
            const object = {
              id: snap.key,
              items: []
            };
            const day = snap.val();

            for (const property in day) {
              if (day.hasOwnProperty(property)) {
                object.items.push(day[property]);
              }
            }

            object.items.forEach((item) => {
              if (item.object == that.filter.object || that.filter.object == '' || !that.filter.object) {
                if (item.objectid == that.filter.objectid || that.filter.objectid == '' || !that.filter.objectid) {
                  if (
                    item.relatedobject == that.filter.relatedobject ||
                    that.filter.relatedobject == '' ||
                    !that.filter.relatedobject
                  ) {
                    if (
                      item.relatedobjectid == that.filter.relatedobjectid ||
                      that.filter.relatedobjectid == '' ||
                      !that.filter.relatedobjectid
                    ) {
                      that.searchResultList.unshift(item);
                    }
                  }
                }
              }

              if (
                !that.objectidList.some((e) => e.objectid === item.objectid) &&
                item.objectid != '' &&
                item.objectid != undefined &&
                item.objectid != null
              ) {
                // if (!that.objectidList.includes(item.objectid)){
                that.objectidList.push(item);
              }
              if (
                !that.relatedobjectidList.some((e) => e.relatedobjectid === item.relatedobjectid) &&
                item.relatedobjectid != '' &&
                item.relatedobjectid != undefined &&
                item.relatedobjectid != null
              ) {
                // if (!that.relatedobjectidList.includes(item.relatedobjectid)){
                that.relatedobjectidList.push(item);
              }
            });
          });

          that.dataSource.data = that.searchResultList;
          that.objectidList = _.chain(that.objectidList)
            .sortBy('object')
            .sortBy('objectid')
            .value();
          that.relatedobjectidList = _.chain(that.relatedobjectidList)
            .sortBy('relatedobject')
            .sortBy('relatedobjectid')
            .value();
        },
        function(err) {
          that.ms.alertError('Fehler: ' + (err as any).code, err.message);
        }
      );
  }

  export2Excel() {
    this.es.exportAsExcelFile(this.dataSource.data, 'persons');
  }
}
