import { enableProdMode, importProvidersFrom } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
// import * as Sentry from "@sentry/angular";
// import { Integrations } from "@sentry/tracing";
import 'zone.js';

import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { MaterialModule } from './app/material/material.module';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { provideAnimations } from '@angular/platform-browser/animations';
import { PageNotFoundComponent } from './app/page-not-found/page-not-found.component';
import { JournalComponent } from './app/journal/journal.component';
import { ResetPasswordComponent } from './app/reset-password/reset-password.component';
import { LoginComponent } from './app/login/login.component';
import { SupervisorOrderCreateComponent } from './app/home/supervisor-order-create/order-create.component';
import { OrderDisplayComponent } from './app/home/order-display/order-display.component';
import { HomeComponent } from './app/home/home.component';
import { provideRouter, Routes } from '@angular/router';
import { AngularFireModule } from '@angular/fire/compat';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const config = {
  apiKey: 'AIzaSyCsrUohUQx5BerpaWbt2fCF4kosMD_Tqms',
  authDomain: 'sah-i-88870.firebaseapp.com',
  databaseURL: 'https://sah-i-88870.firebaseio.com',
  projectId: 'sah-i-88870',
  storageBucket: 'sah-i-88870.appspot.com',
  messagingSenderId: '372696162975'
};
firebase.initializeApp(config);
const appRoutes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'order/:id', component: OrderDisplayComponent },
  { path: 'order-create', component: SupervisorOrderCreateComponent },
  { path: 'dashboard', component: HomeComponent },
  { path: 'login', component: LoginComponent },
  { path: 'reset', component: ResetPasswordComponent },
  { path: 'bildung', loadChildren: () => import('./app/bildung/bildung.module').then((m) => m.BildungModule) },
  { path: 'interpret', loadChildren: () => import('./app/interpret/interpret.module').then((m) => m.InterpretModule) },
  {
    path: 'stammdaten',
    loadChildren: () => import('./app/stammdaten/stammdaten.module').then((m) => m.StammdatenModule)
  },
  {
    path: 'auswertung',
    loadChildren: () => import('./app/auswertung/auswertung.module').then((m) => m.AuswertungModule)
  },
  { path: 'admin', loadChildren: () => import('./app/admin/admin.module').then((m) => m.AdminModule) },
  { path: 'journal', component: JournalComponent },
  { path: '**', component: PageNotFoundComponent }
];

// Sentry.init({
//   dsn: "https://361eb0ea801d4d8d86413bd7762f6b05@o416509.ingest.sentry.io/5572819",
//   autoSessionTracking: true,
//   integrations: [
//     new Integrations.BrowserTracing({
//       tracingOrigins: ["localhost", "https://office.sah-sh.ch"],
//       routingInstrumentation: Sentry.routingInstrumentation,
//     }),
//   ],

//   // We recommend adjusting this value in production, or using tracesSampler
//   // for finer control
//   tracesSampleRate: 0.2,
// });

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      BrowserModule,
      AngularFireModule.initializeApp(config),
      NgbModule,
      FormsModule,
      ReactiveFormsModule,
      AngularFireAuthModule,
      MaterialModule
    ),
    provideHttpClient(withInterceptorsFromDi()),
    provideRouter(appRoutes),
    provideAnimations()
  ]
}).catch((err) => console.log(err));
