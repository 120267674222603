import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Auth, sendPasswordResetEmail, signInWithEmailAndPassword } from '@angular/fire/auth';
import { Router } from '@angular/router';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

import firebase from 'firebase/compat/app';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private router: Router, private _location: Location) {
    const that = this;
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        console.log('Signed in as:', user.uid);
        if (that._location.path() === '/login') {
          that.router.navigate(['/dashboard']);
        }
      } else {
        console.log('Signed out');
        that.router.navigate(['/login']);
      }
    });
  }

  onAuthStateChanged() {
    return firebase.auth().onAuthStateChanged;
  }

  logout(): any {
    return firebase.auth().signOut();
  }

  login(username, password) {
    return firebase.auth().signInWithEmailAndPassword(username, password);
  }

  checkCredentials() {
    if (firebase.auth().currentUser === null) {
      this.router.navigate(['/login']);
    }
  }
  getCurrentUser(): any {
    return firebase.auth().currentUser;
  }
  reset(email): any {
    return firebase.auth().sendPasswordResetEmail(email);
  }
  isLoggedIn(): any {
    return false;
  }
}
