@if (searchResultList?.length > 0) {
  <section class="container-fluid padding-top-1x">
    <h2>Präsenz</h2>
    <!-- <h1 class="page-header">Präsenz</h1> -->
    <div class="row">
      <div class="col-sm-12">
        <!-- 		<div class="spinner" *ngIf="showSpinner== true">
        <div class="rect1"></div>
        <div class="rect2"></div>
        <div class="rect3"></div>
        <div class="rect4"></div>
        <div class="rect5"></div>
      </div> -->
      <button type="submit" class="btn btn-default pull-right" (click)="export2Excel()">
        <span class="fa fa-export" aria-hidden="true"></span> Export
      </button>
    </div>
  </div>
  <div class="example-container mat-elevation-z8">
    <div class="example-header">
      <mat-form-field>
        <mat-select placeholder="Semester" [(ngModel)]="ds.selectedYearSemester">
          <mat-option></mat-option>
          @for (item of ds.yearsemester; track item) {
            <mat-option [value]="item.key">{{ item.text }}</mat-option>
          }
        </mat-select>
      </mat-form-field>
      <!-- <mat-form-field>
      <mat-select placeholder="Betreuer" [(ngModel)]="filter.supervisor">
        <mat-option></mat-option>
        <mat-option *ngFor="let item of supervisorList" [value]="item.id" >{{item.firstname}} {{item.lastname}}</mat-option>
      </mat-select>
    </mat-form-field> -->
    <!-- <mat-form-field>
    <mat-select placeholder="Klasse" [(ngModel)]="filter.class">
      <mat-option></mat-option>
      <mat-option *ngFor="let item of classList" [value]="item.id" >{{item.name}}</mat-option>
    </mat-select>
  </mat-form-field> -->
  <mat-form-field>
    <input matInput [matDatepicker]="datefrom" placeholder="Start Datum" [(ngModel)]="filter.datefrom" />
    <mat-datepicker-toggle matSuffix [for]="datefrom"></mat-datepicker-toggle>
    <mat-datepicker #datefrom></mat-datepicker>
  </mat-form-field>
  <mat-form-field>
    <input matInput [matDatepicker]="dateto" placeholder="End Datum" [(ngModel)]="filter.dateto" />
    <mat-datepicker-toggle matSuffix [for]="dateto"></mat-datepicker-toggle>
    <mat-datepicker #dateto></mat-datepicker>
  </mat-form-field>
  <mat-form-field>
    <mat-select placeholder="Aktive/Inaktiv" [(ngModel)]="filter.active">
      <mat-option value="A">Aktive</mat-option>
      <mat-option value="I">Inaktive</mat-option>
    </mat-select>
  </mat-form-field>
  <button mat-icon-button color="primary" (click)="doSearch()">
    <mat-icon>loop</mat-icon>
  </button>
</div>
<!-- <div class="example-header">
<mat-form-field>
  <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
</mat-form-field>
</div> -->
<mat-table #table [dataSource]="dataSource" matSort>
  <!-- Position Column -->
  <ng-container matColumnDef="supervisor">
    <mat-header-cell *matHeaderCellDef mat-sort-header> Betreuer </mat-header-cell>
    <mat-cell *matCellDef="let element">
      {{ element.supervisor | conversionExit: 'supervisor' | async | resolveFullname }}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="lastname">
    <mat-header-cell *matHeaderCellDef mat-sort-header> Nachname</mat-header-cell>
    <mat-cell *matCellDef="let element"> {{ element.lastname }} </mat-cell>
  </ng-container>
  <ng-container matColumnDef="firstname">
    <mat-header-cell *matHeaderCellDef mat-sort-header> Vorname </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{ element.firstname }} </mat-cell>
  </ng-container>
  <ng-container matColumnDef="mobile">
    <mat-header-cell *matHeaderCellDef mat-sort-header> Mobile </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{ element.mobile }} </mat-cell>
  </ng-container>
  <ng-container matColumnDef="class">
    <mat-header-cell *matHeaderCellDef mat-sort-header> Klasse </mat-header-cell>
    <mat-cell *matCellDef="let element">
      {{ element.class | conversionExit: 'class' | async | resolveName }}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="unexcused">
    <mat-header-cell *matHeaderCellDef mat-sort-header> Unentschuldigte Absenzen </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{ element.foreign.calculation.unexcused }} </mat-cell>
  </ng-container>
  <ng-container matColumnDef="attendance">
    <mat-header-cell *matHeaderCellDef mat-sort-header> Anwesenheit in % </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{ element.foreign.calculation.attendance }} </mat-cell>
  </ng-container>
  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row [ngClass]="{ warning: !row.foreign.classPersonActive, danger: row.foreign.classPersonDroppedout }"
  *matRowDef="let row; columns: displayedColumns"></mat-row>
</mat-table>
<mat-paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
  (page)="pageEvent = $event">
</mat-paginator>
</div>
</section>
}