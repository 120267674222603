/* src/app/home/home.component.css */
mat-cell {
  display: flex;
  justify-content: flex-start;
}
.actioncol {
  flex: 0 0 55px;
}
.statuscol {
  flex: 0 0 105px;
}
/*# sourceMappingURL=home.component.css.map */
