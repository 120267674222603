<section class="container-fluid padding-top-5x">
  <h1 class="page-header">Dolmetsch Auftrag erfassen</h1>

  <div class="example-container">
    <mat-form-field>
      <mat-select placeholder="Kunde" (selectionChange)="selectCustomer()" [(ngModel)]="newItem.customerId">
        @for (item of customers; track item) {
          <mat-option [value]="item.id">{{ item.name }}</mat-option>
        }
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-select placeholder="Kategorie" [(ngModel)]="newItem.dolmetschCategoryId">
        @for (item of dolmetschCategories | async; track item) {
          <mat-option [value]="item.id">{{ item.title }}</mat-option>
        }
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-select placeholder="Art" [(ngModel)]="newItem.dolmetschTypeId">
        @for (item of dolmetschTypes | async; track item) {
          <mat-option [value]="item.id">{{ item.title }}</mat-option>
        }
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-select placeholder="Region" [(ngModel)]="newItem.regionId">
        @for (item of regions | async; track item) {
          <mat-option [value]="item.id">{{ item.title }}</mat-option>
        }
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-select placeholder="Sprache" [(ngModel)]="newItem.languageId">
        @for (item of languages | async; track item) {
          <mat-option [value]="item.id">{{ item.language }}</mat-option>
        }
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <input matInput placeholder="Strasse" [(ngModel)]="newItem.street" />
    </mat-form-field>
    <mat-form-field>
      <input matInput placeholder="PLZ" [(ngModel)]="newItem.postalCode" />
    </mat-form-field>
    <mat-form-field>
      <input matInput placeholder="City" [(ngModel)]="newItem.city" />
    </mat-form-field>

    <br />
    <br />
    <h3>Kontaktdaten Auftraggeber</h3>
    <mat-form-field>
      <input matInput placeholder="Vorname" [(ngModel)]="newItem.contactFirstname" />
    </mat-form-field>
    <mat-form-field>
      <input matInput placeholder="Nachname" [(ngModel)]="newItem.contactLastname" />
    </mat-form-field>
    <mat-form-field>
      <input matInput placeholder="eMail" [(ngModel)]="newItem.contactEmail" />
    </mat-form-field>
    <mat-form-field>
      <input matInput placeholder="Telefon" [(ngModel)]="newItem.contactTelefon" />
    </mat-form-field>
    <br />
    <br />
    <h3>Angaben zum Auftrag</h3>

    <mat-form-field>
      <input matInput placeholder="Verfahrensschritt" [(ngModel)]="newItem.step" />
    </mat-form-field>
    <!-- <mat-form-field>
    <input matInput placeholder="GS Nr." [(ngModel)]="newItem.gs_nr"  >
  </mat-form-field>
  <mat-form-field>
    <input matInput placeholder="Z NR." [(ngModel)]="newItem.gs_znr"  >
  </mat-form-field> -->
  <mat-form-field>
    <input matInput placeholder="Name Klient" [(ngModel)]="newItem.nameendcustomer" />
  </mat-form-field>
  <mat-checkbox [(ngModel)]="newItem.extraSheet">Zusatzblatt benötigt</mat-checkbox>

  <br />
  @if (isWrittenTranslation() != true) {
    <h3>Zeiten</h3>
  }
  @if (isWrittenTranslation() == true) {
    <h3>Schriftliche Übersetzung</h3>
  }

  <mat-form-field>
    <input matInput [matDatepicker]="picker" placeholder="Datum" [value]="newItem.dateTo"
      [(ngModel)]="newItem.dateFrom" />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    @if (isWrittenTranslation() != true) {
      <mat-form-field>
        <input type="time" matInput placeholder="Start Uhrzeit" step="300" [(ngModel)]="newItem.dateFromTime" />
      </mat-form-field>
    }

    <!-- 	<mat-form-field>
    <input matInput [matDatepicker]="picker2" placeholder="End Datum" [value]="newItem.dateFrom" [(ngModel)]="newItem.dateTo">
    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
    <mat-datepicker #picker2></mat-datepicker>
  </mat-form-field> -->

  @if (isWrittenTranslation() != true) {
    <mat-form-field>
      <input type="time" matInput placeholder="End Uhrzeit" step="300" [(ngModel)]="newItem.dateToTime" />
    </mat-form-field>
  }

  <!-- <mat-form-field *ngIf="isWrittenTranslation() == true">
  <input matInput placeholder="Anzahl Seiten" [(ngModel)]="newItem.numberOfPages" />
</mat-form-field> -->
@if (isWrittenTranslation() == true) {
  <mat-form-field>
    <mat-select placeholder="Anzahl Seiten" [(ngModel)]="newItem.numberOfPages">
      <mat-option value="0.5">0.5 Seite</mat-option>
      <mat-option value="0.5">1.0 Seite</mat-option>
      <mat-option value="0.5">1.5 Seiten</mat-option>
      <mat-option value="0.5">2.0 Seiten</mat-option>
      <mat-option value="0.5">2.5 Seiten</mat-option>
      <mat-option value="0.5">3.0 Seiten</mat-option>
      <mat-option value="0.5">3.5 Seiten</mat-option>
      <mat-option value="0.5">4.0 Seiten</mat-option>
      <mat-option value="0.5">4.5 Seiten</mat-option>
      <mat-option value="0.5">5.0 Seiten</mat-option>
      <mat-option value="0.5">5.5 Seiten</mat-option>
      <mat-option value="0.5">6.0 Seiten</mat-option>
      <mat-option value="0.5">6.5 Seiten</mat-option>
      <mat-option value="0.5">7.0 Seiten</mat-option>
      <mat-option value="0.5">7.5 Seiten</mat-option>
      <mat-option value="0.5">8.0 Seiten</mat-option>
      <mat-option value="0.5">8.5 Seiten</mat-option>
      <mat-option value="0.5">9.0 Seiten</mat-option>
      <mat-option value="0.5">9.5 Seiten</mat-option>
      <mat-option value="0.5">10.0 Seiten</mat-option>
      <mat-option value="0.5">10.5 Seiten</mat-option>
      <mat-option value="0.5">11.0 Seiten</mat-option>
      <mat-option value="0.5">11.5 Seiten</mat-option>
      <mat-option value="0.5">12.0 Seiten</mat-option>
      <mat-option value="0.5">12.5 Seiten</mat-option>
      <mat-option value="0.5">13.0 Seiten</mat-option>
      <mat-option value="0.5">13.5 Seiten</mat-option>
      <mat-option value="0.5">14.0 Seiten</mat-option>
      <mat-option value="0.5">14.5 Seiten</mat-option>
      <mat-option value="0.5">15.0 Seiten</mat-option>
      <mat-option value="0.5">15.5 Seiten</mat-option>
      <mat-option value="0.5">16.0 Seiten</mat-option>
      <mat-option value="0.5">16.5 Seiten</mat-option>
      <mat-option value="0.5">17.0 Seiten</mat-option>
      <mat-option value="0.5">17.5 Seiten</mat-option>
      <mat-option value="0.5">18.0 Seiten</mat-option>
      <mat-option value="0.5">18.5 Seiten</mat-option>
      <mat-option value="0.5">19.0 Seiten</mat-option>
      <mat-option value="0.5">19.5 Seiten</mat-option>
      <mat-option value="0.5">20.0 Seiten</mat-option>
    </mat-select>
  </mat-form-field>
}

@if (isWrittenTranslation() == true) {
  <mat-form-field>
    <mat-select placeholder="Schwierigkeitsgrad" [(ngModel)]="newItem.difficulty">
      <mat-option value="Einfach">Einfach</mat-option>
      <mat-option value="Schwierig">Schwierig</mat-option>
    </mat-select>
  </mat-form-field>
}
<!--<br>
<br>
  <mat-form-field>
    <input matInput placeholder="Dolmetschzeit" maxlength="5" minlength="5" [(ngModel)]="newItem.durationDolmetsch"  >
  </mat-form-field>
  <mat-form-field>
    <input matInput placeholder="Reisezeit" maxlength="5" minlength="5" [(ngModel)]="newItem.durationTravel"  >
  </mat-form-field>
  <mat-form-field>
    <input matInput placeholder="Wartezeit" maxlength="5" minlength="5" [(ngModel)]="newItem.durationWait"  >
  </mat-form-field>


  -->
</div>

<button mat-raised-button color="primary" [disabled]="canBeSaved() == false" (click)="save()">Speichern</button>
<br />
<br />
</section>