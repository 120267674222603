<div class="padding-top-5x">
  <section class="container-fluid">
    <h1 class="page-header">Übersicht für {{ ds?.userProfile?.firstname }} {{ ds?.userProfile?.lastname }}</h1>

  </section>

  @if (classList.length > 0) {
    <h2>Meine Klassen</h2>
  }
  @for (class of classList; track class) {
    <div>
      <h3>
        <a (click)="gotoClass(class)">{{ class.name }}</a>
      </h3>
      <classpersons [classId]="class.id"></classpersons>
      <br />
      <br />
    </div>
  }

  <app-supervisor-presence></app-supervisor-presence>

  @for (customer of customerList | async; track customer) {
    <div>
      <interpret-orders [customer]="customer"></interpret-orders>
    </div>
  }
</div>