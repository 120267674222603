<section class="container-fluid padding-top-5x">
  <h1 class="page-header">Passwort zurücksetzen</h1>

  <div class="row">
    <div class="center-block" style="width: 400px;">
      <form #f="ngForm" class="ui form">
        <div class="form-group">
          <label for="key">Username</label>
          <input
            type="text"
            class="form-control"
            id="username"
            required
            [(ngModel)]="resetemail"
            name="username"
            placeholder="Username"
          />
        </div>

        <button class="btn btn-default" (click)="reset()">Passwort zurücksetzen</button>
      </form>
    </div>
  </div>
</section>
